import { Component, OnInit } from '@angular/core';
import { MetatagService } from 'src/app/services/metatag.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private meta: MetatagService) { 
    meta.setMetaTags(
      {
        title: "Codeible",
        description: "Learn how to build and deploy applications from various video tutorials. Also available on Android.",
        image: "https://firebasestorage.googleapis.com/v0/b/codeible.appspot.com/o/project_files%2Ffeature_graphic.png?alt=media&token=245aa2d1-d195-4898-adbd-29a2b7ce7e6f"
      }
    );
  }

  ngOnInit() {
  }

}
