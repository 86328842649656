import { Component, OnInit, Input } from '@angular/core';
import { TutorialHeaderFunctions } from 'src/app/classes/TutorialHeaderFunctions';




@Component({
  selector: 'app-course-header',
  templateUrl: './course-header.component.html',
  styleUrls: ['./course-header.component.css']
})
export class CourseHeaderComponent extends TutorialHeaderFunctions implements OnInit {
  @Input() course;
  @Input() courses: any[];
  _tags: string[];

  constructor() { 
    super();
  }

  ngOnInit() {
  }

  courseTagsToArray() {
    return this.course.document.fields.tags.stringValue.split(",");
  }
  getCost(cost: number): string {
    try {
      let costToString = cost.toString();
      if (cost > 0) {
        return `${cost / 100}`;
      } else {
        return "Free"
      }
    } catch (err) {
      return "Free"
    }
  }

  changeColor(text: string, element: HTMLElement) {
    let r = 0;
    let g = 0;
    let b = 0;
    for (let i = 0; i < text.length; i++) {
      const index = i % 3;
      if (index == 0) {
        r += text.charCodeAt(i);
      } else if (index == 1) {
        g += text.charCodeAt(i);
      } else if (index == 2) {
        b += text.charCodeAt(i);
      }
    }
    const backgroundR = r % 255;
    const backgroundG = g % 255;
    const backgroundB = b % 255;
    //element.style.backgroundColor = `rgb(${backgroundR}, ${backgroundG}, ${backgroundB})`;
    const textR = 255 - backgroundR;
    const textG = 255 - backgroundG;
    const textB = 255 - backgroundB;
    //element.style.color = `rgb(${textR}, ${textG}, ${textB})`;
    element.style.color = "beige";
    return text;
  }






}
export interface Course {
  title?: string,
  cost?: string,
  desc?: string,
  tags?: string,
  imgUrl?: string
  creatorId?: string,
  published?: boolean,
  /*tutorials?: Tutorial []*/
  id?: string;
}
/*
export interface ICourse {
  title?: string,
  cost?: string,
  desc?: string,
  tags?: string,
  imgUrl?: string
  creatorId?: string,
  published?: boolean,
  tutorials?: ITutorialMetaData []
}

export class Course {
  iCourse: ICourse;
  id: string;
  tags: string [];
  constructor(iCourse: ICourse, id: string){
    this.iCourse = iCourse;
    this.id = id;
    this.tags = this.iCourse.tags.split(",");
  }
}
*/