 <div class="course-item">
  <img loading="lazy" arial-label="Course Image" alt="Course Image" class="course-item-img" src="{{course?.document.fields.imgUrl.stringValue}}">
  <div class="course-metadata-container">
    <div *ngIf="course?.document.fields.tags.stringValue.length > 0" class="tags-container">
      <div #tagsElement *ngFor="let tag of courseTagsToArray()" class="tag">
        <img [src]="getTagIcon(tag)" style="width: 2em; border-radius: 50%;  margin-right: .2em;">
      </div>
    </div>
    <div class="course-item-title">{{course?.document.fields.title.stringValue}}</div>
    <div class="course-item-desc">{{course?.document.fields.desc.stringValue}}</div>
  </div>
  <!--<div *ngIf="course?.document.fields.tags.stringValue.length > 0" class="tags-container">
    <div #tagsElement *ngFor="let tag of courseTagsToArray()" class="tag">{{changeColor(tag, tagsElement)}}</div>
  </div>-->
  <!--  <h2 class="course-price">{{course?.cost > 0? "$": ""}} <span>{{getCost(course?.document.fields.cost.stringValue) == "Free"? "Free": getCost(course?.document.fields.cost.stringValue) | number: "1.2-2"}}</span></h2>-->
</div>