import { Component, OnInit } from '@angular/core';
import { FirebaseTSFirestore, Where } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { DBNames } from 'src/app/classes/DBNames';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-initial-signup',
  templateUrl: './initial-signup.component.html',
  styleUrls: ['./initial-signup.component.css']
})
export class InitialSignupComponent implements OnInit {
  firestore: FirebaseTSFirestore;
  updatingName = false;
  constructor() { 
    this.firestore = new FirebaseTSFirestore();
  }

  ngOnInit() {

  }

  onContinueButtonClick(nameInput: HTMLInputElement){
    if(this.updatingName || nameInput.value.length < 2) return;
    this.updatingName = true;
    this.firestore.getCollection(
      {
        path: [
          DBNames.USERS_COLLECTION
        ],
        where: [
          new Where("name", "==", nameInput.value)
        ],
        onComplete: result => {
          if(result.empty) {
            this.firestore.update(
              {
                path: [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id],
                data: {
                  name: nameInput.value
                },
                onComplete: (docRef) => {
                  this.updatingName = false;
                },
                onFail: err => {
                  this.updatingName = false;
                  alert(err);
                  
                }
              }
            );         
          } else {
            alert("Name is already taken, use another.");
            this.updatingName = false;
          }
        }
      }
    );
  }

}
