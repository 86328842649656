<section id="new-tutorial">
  <div *ngIf="hasPrivilege(); else noPriv">
    <!--Creator tools-->
    <div id="creator-tools">
      <div id="creator-tools-container">
        <div class="creator-tool" (click)="save()">
          <span class="material-icons">save</span>
          <span>Save</span>
        </div>
        <div *ngIf="hasTutorial()" class="creator-tool" (click)="onPreviewClick()">
          <span class="material-icons">preview</span>
          <span>Preview</span>
        </div>
        <app-add-to-course *ngIf="hasTutorial()" [tutorial]="tutorial"></app-add-to-course>
        <!--
          <div *ngIf="hasTutorial()" class="creator-tool" (click)="onDeleteClick()">
            <span class="material-icons">delete</span>
            <span>Delete</span>
          </div>
        -->
      </div>
    </div>
    <div id="new-tutorial-page">

      <!--HEADER-->
      <div id="tutorial-header">
        <input id="tutorial-title-inp" placeholder="Title" value="{{tutorial?.title}}" maxlength="150">
        <input id="tutorial-description" placeholder="Description" value="{{tutorial?.description}}" maxlength="150">
        <input id="tutorial-authors" placeholder="Author(s)" value="{{tutorial?.authors}}" maxlength="150">
        <input #tutorialCost id="tutorial-cost" placeholder="0" value="{{tutorial?.cost}}" type="text" maxlength="10"
          (keyup)="onCostKeyup(tutorialCost)">
        <input id="tutorial-tags" placeholder="tag1, tag2, tag3, ..." value="{{tutorial?.tags}}" type="text"
          maxlength="200">
        <!--<button (click)="onPreviewClick()">Preview</button>-->
      </div>
      <!--Content-->
      <div id="tutorial-content">
        <!--Tutorial contents-->
        <div #contentContainer cdkDropList [cdkDropListData]="getContentArray()"
          (cdkDropListDropped)="onContentDropped($event)" class="content-container"
          *ngFor="let content of getContentArray()">
          <app-tutorial-content [content]="content"></app-tutorial-content>
          <!--Content Creator Tools-->
          <div class="content-creator-tools">
            <span class="material-icons" (click)="onEditTutorialContentClick(contentContainer, editTextbox)">edit</span>
            <span *ngIf="content != selectedInsertContent" class="material-icons"
              (click)="onInsertWithTutorialClick(content)">swap_horiz</span>
            <span class="material-icons" (click)="onDeleteTutorialContentClick(content)">delete</span>
          </div>
        </div>
        <!--TODO: EDIT FEATURE-->
        <!--<div class="k-text-editor" #editTextbox></div>-->
        <div style="margin: 1em 0 0 0;">

          <!--Indicator-->
          <div id="add-content-indicator" class="k-center-box" *ngIf="isIndicatorPhase()" (click)="onIndicatorClick()">
            <div class="k-center-box-content"><span class="material-icons">add</span></div>
          </div>
          <!--Selection-->
          <div id="add-content-selection" *ngIf="isSelectionPhase()" (click)="onSelectionClick()">
            <div id="content-selection-item" (click)="onSelectionItemClick(1)"></div>
            <div id="content-selection-item" (click)="onSelectionItemClick(2)"></div>
            <div id="content-selection-item" (click)="onSelectionItemClick(3)"></div>
          </div>
          <!--Selection Result-->
          <div [ngClass]="{'show': isResultPhase(), 'hide': !isResultPhase()}">
            <!--Textbox-->
            <div [ngClass]="{'show': selectedTextbox(), 'hide': !selectedTextbox()}">
              <div style="margin: 0 0 .5em 0;">
                <input #focusCB name="text-box-title" type="checkbox" value="{{focus}}"
                  (click)="onFocusCheckboxClick(focusCB)"> Focus
              </div>
              <div style="margin: 0 0 .5em 0;">
                <input #isTitleCB name="text-box-title" type="checkbox" value="{{isTitle}}"
                  (click)="onIsTitleCheckboxClick(isTitleCB)"> Title
              </div>
              <div style="margin: 0 0 .5em 0;">
                <input #isSubtitleCB name="text-box-title" type="checkbox" value="{{isSubtitle}}"
                  (click)="onIsSubitleCheckboxClick(isSubtitleCB)"> Subtitle
              </div>
              <div style="margin: 0 0 .5em 0;">
                <input #isGistCB name="text-box-title" type="checkbox" value="{{isGist}}"
                  (click)="onIsGistCheckboxClick(isGistCB)"> Gist
              </div>
              <div class="k-text-editor" #textbox></div>

              <div>
                <button (click)="onConfirmAddTextClick(textbox)">Confirm</button>
                <button (click)="onCancelAddTextClick()">Cancel</button>
              </div>
            </div>
            <!--Image-->
            <div [ngClass]="{'show': selectedImage(), 'hide': !selectedImage()}">
              <div class="image-display"><img #imageDisplay /></div>
              <div><input #imageInput type="file" (change)="onImageChange(imageInput, imageDisplay)" /></div>
              <div>
                <button (click)="onConfirmAddImageClick(imageInput, imageDisplay)">Confirm</button>
                <button (click)="onCancelAddTextClick()">Cancel</button>
              </div>
            </div>
            <!--Video-->
            <div [ngClass]="{'show': selectedVideo(), 'hide': !selectedVideo()}">
              <div><input type="text" #videoInput /></div>
              <div>
                <button (click)="onConfirmAddVideoClick(videoInput)">Confirm</button>
                <button (click)="onCancelAddTextClick()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {{ setupTextEditor()}}
    </div>
  </div>

  <!--Does not have privileges-->
  <ng-template #noPriv>
    <div *ngIf="!hasPrivilege()" id="new-tutorial-no-access">
      <h1>Cannot access this page.</h1>
    </div>
  </ng-template>
</section>
<app-footer></app-footer>