<div class="k-center-box" style="height: 100%;">
  <div class="k-center-box-content">
    <div id="intro" >  
      <h1>{{title}}</h1>
      <p>{{description}}</p>
      <h3>{{infoCollection}}</h3>
      <p>{{infoCollectionDesc}}</p>
      <h3>{{childrePrivacy}}</h3>
      <p>{{childrenPrivacyDesc}}</p>
    </div>
  </div>
</div>