<div id="explore">
  <div id="explore-content">
    <div class="k-input" left-to-right style="margin: 0px 0px 1.5em 0px;">
      <input #exploreSearchInput id="explore-search-input" maxlength="100" (keyup.enter)="getTutorials(exploreSearchInput.value)"/>
      <button id="explore-search-button" (click)="getTutorials(exploreSearchInput.value)">SEARCH</button>
    </div>
    <div class="tutorial-header" *ngFor="let tutorial of tutorials" (click)="onTutorialClick(tutorial)">
      <h1>{{tutorial?.title}}</h1>
      <p>{{tutorial?.description}}</p>
      <p>By <span class="tutorial-author">{{tutorial?.authors}}</span></p>
      <div *ngIf="tutorial?.tags?.length > 0" class="tags-container">
        <div #tagsElement *ngFor="let tag of tutorial?.tags" class="topic">{{changeColor(tag, tagsElement)}}</div>
      </div>
      <hr>
      <h2 class="tutorial-price">{{tutorial.cost > 0? "$": ""}} <span class="tutorial-price-tag">{{getCost(tutorial.cost) == "Free"? "Free": getCost(tutorial.cost) | number: "1.2-2"}}</span></h2>
    </div>
    <button (click)="onLoadMoreButtonClick()">Load More</button>
  </div>
</div>

<app-footer></app-footer>