import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FirebaseTSFirestore, OrderBy, Where } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { DBNames } from 'src/app/classes/DBNames';

import { Router } from '@angular/router';
import { Course } from '../../tools/course-header/course-header.component';
import { MetatagService } from 'src/app/services/metatag.service';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';



@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  firestore: FirebaseTSFirestore;
  courses: any;
  constructor(private router: Router, private meta: MetatagService, @Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient) { 
    this.meta.setMetaTags(
      {
        title: "Codeible Video Tutorials",
        description: "View a list of awersome video tutorials from Codeible.com", 
        image: "https://codeible.com/assets/images/icons/codible_256.png" 
      } 
    );
  }

  async ngOnInit(): Promise<void>  {
    this.getCoursesWithHttp();
  }

  getCourses(){
    if(isPlatformBrowser(this.platformId)) {
      this.firestore = new FirebaseTSFirestore();
      this.courses = new Array<Course>();
      this.firestore.getCollection(
        {
          path: [DBNames.C_COURSES],
          where: [new Where("published", "==", true), new OrderBy("title", "asc")],
          onComplete: (result) => {
            result.docs.forEach(
              c => {
                let _course = <Course>c.data();
                _course.id = c.id;
                this.courses.push(_course);
  
              }
            );
          }
        }
      );
    }
  }

  async getCoursesWithHttp(){
    this.courses = await this.http.post("https://firestore.googleapis.com/v1/projects/codeible/databases/(default)/documents:runQuery", {
      "structuredQuery": {
        "from": [
          {
            "collectionId": "Courses"
          }
        ],
        where: {
          compositeFilter: {
            filters: [
              {
                fieldFilter: {
                  field: {
                    fieldPath: 'published'
                  },
                  op: 'EQUAL',
                  value: {
                    booleanValue: true
                  }
                }
              }
            ], op: 'AND'
          }
        },
        "orderBy": [
            {
                "field":  {
                    "fieldPath": "title"
                },
                "direction": "ASCENDING"
            }
        ],
      }
    });

  }

  genCourseRouter(course){
    let location = course.document.name;
    let docId = location.substr(location.lastIndexOf("/") + 1);
    return `../view/videotutorial/${docId}`;
  }
  onCourseClick(course) {
    let location = course.document.name;
    let docId = location.substr(location.lastIndexOf("/") + 1);
    //window.location.href = `view/course/${course.id}`;
    this.router.navigate(['view/videotutorial/' + docId]);
  } 
 

}
