<div style="height: 100%;" class="k-center-box">
  <div  class="k-center-box-content" id="cookies-policy-content">
    <div id="cookie-desc">
      <h1>{{title}}</h1>
      <p #bulletEle1>{{setInnerHTML(bulletEle1, bullet1)}}</p>
      <p #bulletEle2>{{setInnerHTML(bulletEle2, bullet2)}}</p>
      <p #bulletEle3>{{setInnerHTML(bulletEle3, bullet3)}}</p>
      <p>{{group2}}</p>
    </div>
  </div>
</div>