<!--
<div style="text-align: center;">
  <iframe id="video-link" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
-->
<div>
  <input #videoLinkInput id="video-link-input" placeholder="Video Url" />
</div>
<button (click)="addVideoLinkButtonClick()">Add</button>

<!--(change)="onVideoInputChange(videoLinkInput)" (paste)="onVideoInputChange(videoLinkInput)"-->