export class TutorialHeaderFunctions {
    getTagIcon(tag: string) {
        switch (tag.toLowerCase()) {
          case "android":
            return "assets/images/icons/Android.jpg"
          case "angular":
            return "assets/images/icons/Angular.jpg"
          case "firebase":
            return "assets/images/icons/Firebase.jpg"
          case "typescript":
            return "assets/images/icons/TypeScript.jpg"
          case "kotlin":
            return "assets/images/icons/Kotlin.jpg"
          case "googleplay":
            return "assets/images/icons/GooglePlay.jpg"
          case "python":
            return "assets/images/icons/Python.jpg"
          case "html":
            return "assets/images/icons/Html.jpg"
          case "css":
            return "assets/images/icons/Css.jpg"
          case "aws":
            return "assets/images/icons/aws.png"
          case "unrealengine":
            return "assets/images/icons/ue.png"
        }
      }
}