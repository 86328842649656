<div [ngSwitch]="contentParser?.type" >
  <div *ngSwitchCase="1" [ngStyle]="contentParser?.getStyles()" class="text-content-container">
    <p [ngClass]="{'content-paragraph-focused': isFocused()}" [innerHTML]="contentParser?.content" #contentParagraph></p>
    <button *ngIf="isFocused()" class="copy-button" (click)="addTextToClipboard(contentParagraph)">Copy</button>
  </div>
  <div *ngSwitchCase="2" style="text-align: center;"><img loading="lazy" aria-label="Content Image" [src]="contentParser?.content"  [ngStyle]="contentParser?.getStyles()" alt="Image from Codeible.com"/></div>
  <div *ngSwitchCase="3" style="text-align: center;"><iframe class="video-iframe" frameborder="0" [src]="contentParser?.getVideoUrl()" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
  <div *ngSwitchCase="4" style="text-align: center;">
    <iframe #gistFrame type="text/javascript" class="gist-iframe"></iframe>
  </div>
  
  <p *ngSwitchDefault>Default</p>
</div>
