

/*export class Quiz {
    iQuiz: IQuiz;
    constructor(iQuiz: IQuiz) {
        this.iQuiz = iQuiz;

    }

}

export interface IQuiz {
    quizItems: QuizItem[];
}

export class QuizItem {
    public static readonly MULTIPLE_CHOICE = "MC";
    public static readonly SHORT_ANSWER = "SA";
    specialEffects: string;
    type: string;
    completed = false;
    score: number;
    correctAnswer: string;

    constructor() {

    }
}




export class MultipleChoice extends QuizItem {
    iMultipleChoice: IMultipleChoice;

    constructor(iMultipleChoice: IMultipleChoice) {
        super();
        this.iMultipleChoice = iMultipleChoice;
        this.specialEffects = this.iMultipleChoice.specialEffects;
        this.type = this.iMultipleChoice.type;
        this.score = this.iMultipleChoice.score;
        this.correctAnswer = this.iMultipleChoice.correctAnswer;
    }

}


export interface IMultipleChoice {
    question?: string;
    answers?: string[];
    specialEffects?: string;
    type?: string;
    score?: number;
    correctAnswer?: string;
}
export class ShortAnswer extends QuizItem {
    iShortAnswer: IShortAnswer;

    constructor(iShortAnswer: IShortAnswer) {
        super();
        this.iShortAnswer = iShortAnswer;
        this.specialEffects = this.iShortAnswer.specialEffects;
        this.type = this.iShortAnswer.type;
        this.score = this.iShortAnswer.score;
        this.correctAnswer = this.iShortAnswer.correctAnswer;
    }

}
export interface IShortAnswer {
    question?: string;
    specialEffects?: string;
    type?: string;
    score?: number;
    correctAnswer?: string;
    details?: string;
}


export class QuizSpecialEffects {
    public static readonly GAIN_HEALTH_EFFECT = "GAIN_HEALTH";
    public static readonly NO_EFFECTS = "NO_EFFECTS";
}

export class MultipleChoiceOption {
    value: string = "";
}
*/

export interface Quiz {
    title: string;
    topic: string;
    subtopic: string;
    cost: number;
}

export interface QuizQuestion {
    correctAnswer: string;
    details: string;
    question: string;
    score: number;
    type: string;
}



export interface SubtopicQuizHeaders {
    subtopic?: string;
    quizes?: Array<Quiz>;
}

export interface QuizTopicItem {
    title: string;
    subtopics?: string[];
    icon: string;
}

export interface ValueQuizTopicDocument {
    list: QuizTopicItem[];
}

export interface Question {
    type?: QuestionType;
    correctAnswer?: string;
    userAsnwer?: string;
    question?: string;
    score?: number;
    details?: string;
    mcOptions?: string[];
    completed?: boolean;
}

export class QuestionType {
    public static MULTIPLE_CHOICE = "MC";
    public static SHORT_ANSWER = "SA";
}

export interface UserQuizHistory {
    completed?: boolean;
    score?: number;
    highestScoreRecordedOn?: firebase.default.firestore.Timestamp;
    questionResults?: QuizQuestionResult[];
}

export interface QuizSubmitData {
    score?: number;
    quizId?: string;
    maxScore?: number;
    questionResults?: QuizQuestionResult[];
}

export interface QuizQuestionResult {
    question: string;
    correct: boolean;
}

export interface QuizHighScore {
    timestamp?: firebase.default.firestore.Timestamp;
    name?: string;
    score?: number;
    quizId?: string;
    uid?: string;
}

export interface OverallQuizHighScore {
    timestamp?: firebase.default.firestore.Timestamp;
    name?: string;
    score?: number;
}