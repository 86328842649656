<div id="pricing">
  <div>
    <img src="assets/images/icons/pricing.png" />
    <h3>Free</h3>
    <h1>$<span class="dollar">0</span>/Month</h1>
    <hr>
    <div class="pricing-perks">
      <p><i class="material-icons">check</i>Unlimited access to free tutorials</p>
      <p><i class="material-icons">check</i>Unlimited access to free courses</p>
    </div>
    <button id="default-button">Default Plan</button>
  </div>

  <div>
    <img src="assets/images/icons/pricing.png" />
    <h3>Pro</h3>
    <h1>$<span class="dollar">25</span>/3 Months</h1>
    <hr>
    <div class="pricing-perks">
      <p><i class="material-icons">check</i>All of free user's benefits</p>
      <p><i class="material-icons">check</i>Unlimited access to all tutorials</p>
      <p><i class="material-icons">check</i>Unlimited access to all courses</p>
      <!--<p><i class="material-icons">check</i>Post comments in tutorials</p>-->
      <p><i class="material-icons">check</i>Bookmark tutorials</p>
    </div>
    <button (click)="onQuarterlyPlanClick()">Choose Plan</button>
  </div>

  <div>
    <img src="assets/images/icons/pricing.png" />
    <h3>Premium</h3>
    <h1>$<span class="dollar">70</span>/Year</h1>
    <hr>
    <div class="pricing-perks">
      <p><i class="material-icons">check</i> All of quarterly benefits</p>
      <!--<p><i class="material-icons">check</i>Post comments in courses</p>-->
    </div>
    <button (click)="onYearPlanClick()">Choose Plan</button>
  </div>
</div>

<div *ngIf="showPayment" id="pricing-payment">
  <div id="pricing-payment-content">
    <div id="pricing-plan">
      <img src="assets/images/icons/pricing.png" />
      <h3>{{selectedPlan}}</h3>
      <h1>$<span class="dollar">{{selectedPlanPrice}}</span>{{selectedPlanDuration}}</h1>
      <hr>
    </div>
    <div id="paypal-button-container" style="box-sizing: border-box;"></div>
    <div id="pricing-payment-close-button" (click)="onClosePaymentClick()">X</div>
  </div>
  {{renderPayment()}}
</div>
