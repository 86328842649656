<div *ngIf="isEligibleToCreate()" id="create-quiz">
    <div class="quiz-header">
        <div class="quiz-header-input">
            <input #titleInput placeholder="Title"/>
            <input #costInput placeholder="Cost" class="margin-top"/>
            <app-dropdown [dropdownItemList]="topics" (selectedDropdownItem)="onTopicSelected($event)"></app-dropdown>
            <app-dropdown [dropdownItemList]="subtopics" (selectedDropdownItem)="onSubtopicSelected($event)"></app-dropdown>
        </div>
    </div>

    <div *ngFor="let question of questions">
        <div [ngSwitch]="question.type">
            <app-add-multiple-choice  *ngSwitchCase="'MC'" [question]="question"></app-add-multiple-choice >
            <app-add-short-answer *ngSwitchCase="'SA'" [question]="question"></app-add-short-answer>
         </div>
    </div>

    <button (click)="onAddMultipleChoiceClick()">Add Multiple Choice</button>
    <button (click)="onAddShortAnswerClick()">Add Short Answer</button>
    <button (click)="createQuiz(titleInput, costInput)">Create</button>
</div>


<div *ngIf="!isEligibleToCreate()" class="no-access">
    <h1>Cannot access this page.</h1>
</div>
  