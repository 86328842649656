import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-google-ad',
  templateUrl: './google-ad.component.html',
  styleUrls: ['./google-ad.component.css']
})
export class GoogleAdComponent implements OnInit {
  @Input() adSlot: string;
  @Input() adStyle: string;
  @Input() adFormat?: string;
  @Input() adLayout?: string;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        try {
          for(let i = 0; i < document.getElementsByTagName("ins").length; i++) {
            (window['adsbygoogle'] = window['adsbygoogle'] || []).push({
  
            });
          }

        } catch (e) {
          //console.error(e);
        }
      }, 1000);
    }

  }

}
