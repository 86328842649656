export class DBNames {
    public static readonly USERS_COLLECTION = "USERS_COLLECTION";
    public static readonly USERS_PURCHASED_COLLECTION = "USERS_PURCHASES_COLLECTION";
    public static readonly USER_QUIZ_HISTORY_COLLECTION = "USER_QUIZ_HISTORY_COLLECTION";
    public static readonly USERS_BOOKMARKS_COLLECTION = "USERS_BOOKMARKS_COLLECTION";
    public static readonly USERS_COURSES_COLLECTION = "USERS_COURSES_COLLECTION";
    public static readonly COURSES_TUTORIALS_COLLECTION = "COURSES_TUTORIALS_COLLECTION";
    
    public static readonly C_TUTORIALS = "Tutorials";
    public static readonly C_TUTORIAL_HEADER_TUTORIAL_CONTENT = "Tutorial Content";
    public static readonly D_TUTORIAL_HEADER_TUTORIAL_CONTENT_DOCUMENT = "Contents";

    public static readonly C_COURSES = "Courses";
    public static readonly C_COURSES_TUTORIAL_LIST = "Tutorials List";
    public static readonly D_COURSES_TUTORIAL_LIST_DOCUMENT = "Tutorials";
    public static readonly C_QUIZES = "Quizes";
    public static readonly C_QUIZ_QUESTIONS = "Questions";

    public static readonly D_QUIZ_QUESTIONS = "Questions";
    public static readonly COURSE_FILES_COLLECTION = "COURSE_FILES";
    public static readonly C_VALUES = "VALUES_COLLECTION";
    public static readonly VALUE_QUIZ_TOPIC = "QUIZ_TOPIC";
    public static readonly C_QUIZ_HIGH_SCORES = "QUIZ_HIGH_SCORES";
    public static readonly C_OVERALL_QUIZ_HIGH_SCORES = "OVERALL_QUIZ_HIGH_SCORES";

}