<div [ngClass]="{'show': show, 'hide': !show}" id="textbox">
  <div class="k-text-editor" id="textbox-editor" #textboxEditor>
  
  </div>
  <div>
    <input checked #leftRadio name="text-box-orientation" type="radio" value="{{left}}" (click)="onOrientaitonClick(leftRadio, textboxEditor)"> {{left}}
    <input #centerRadio name="text-box-orientation" type="radio" value="{{center}}" (click)="onOrientaitonClick(centerRadio, textboxEditor)"> {{center}}
    <input #rightRadio name="text-box-orientation" type="radio" value="{{right}}" (click)="onOrientaitonClick(rightRadio, textboxEditor)"> {{right}}
    <input #setAsTitleCB name="text-box-title" type="checkbox" value="{{setAsTitle}}" (click)="onOrientaitonClick(setAsTitleCB, textboxEditor)"> {{setAsTitle}}
    <input #focusCB name="text-box-title" type="checkbox" value="{{focus}}" (click)="onOrientaitonClick(focusCB, textboxEditor)"> {{focus}}
  </div>
  <button (click)="onConfirmClick(textboxEditor)">Confirm</button>
</div>



