import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { BatchOperation, FirebaseTSFirestore, Where } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { AppComponent } from 'src/app/app.component';
import { DBNames } from 'src/app/classes/DBNames';
import { Quiz, QuizTopicItem, SubtopicQuizHeaders, UserQuizHistory, ValueQuizTopicDocument } from 'src/app/classes/QuizItems';
import { MetatagService } from 'src/app/services/metatag.service';
import { QuizHighScoreViewState } from '../../tools/quiz-high-score-view/quiz-high-score-view.component';


@Component({
  selector: 'app-quizes',
  templateUrl: './quizes.component.html',
  styleUrls: ['./quizes.component.css']
})
export class QuizesComponent implements OnInit {



  quizTopicItens: QuizTopicItem[];
  topics: string[];
  topicIcon: string;
  isQuizSelectorToggled = false;
  subtopicQuizHeaders: Array<SubtopicQuizHeaders>;
  processingQuizPurchaseTransaction = false;

  private firestore: FirebaseTSFirestore;





  constructor(private router: Router, private meta: MetatagService, @Inject(PLATFORM_ID) private platformId: Object) {
    this.firestore = new FirebaseTSFirestore();
    meta.setMetaTags(
      {
        "title": "Codeible - Test Your Knowledge",
        "description": "Learn from quizes and also test what you know.",
        "image": "https://codeible.com/assets/images/icons/codible_256.png"
      }
    );
    this.getQuizTopics();

  }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
      window.scrollTo(0, 0);
    }
  }


  onTopicSelected(topic: string) {
    if (topic) {
      for (let quizTopicItem of this.quizTopicItens) {
        if (topic == quizTopicItem.title) {
          this.meta.setMetaTags(
            {
              "title": `${topic} Quizes`,
              "description": `Learn from quizes and also test what you know for ${topic}.`,
              "image": "https://codeible.com/assets/images/icons/codible_256.png"
            }
          );
          this.populateSubtopicQuizHeaders(quizTopicItem);
          this.getQuizes(quizTopicItem);
          this.topicIcon = quizTopicItem.icon;
          break;
        }
      }

    }
  }



  getQuizSelectorToggleIcon() {
    return this.isQuizSelectorToggled ? "keyboard_arrow_down" : "keyboard_arrow_right";
  }

  getQuizTopics() {
    this.quizTopicItens = new Array<QuizTopicItem>();
    this.topics = new Array<string>();
    this.firestore.getDocument(
      {
        path: [DBNames.C_VALUES, DBNames.VALUE_QUIZ_TOPIC],
        onComplete: (result) => {
          let quizTopicsDoc = <ValueQuizTopicDocument>result.data();
          quizTopicsDoc.list.forEach(
            topic => {
              this.quizTopicItens.push(topic);
              this.topics.push(topic.title);
            }
          );
        }
      }
    );
  }


  populateSubtopicQuizHeaders(topic: QuizTopicItem) {
    this.subtopicQuizHeaders ? this.subtopicQuizHeaders.length = 0 : this.subtopicQuizHeaders = new Array<SubtopicQuizHeaders>();
    try {
      for (let subtopic of topic?.subtopics) {

        this.subtopicQuizHeaders.push(
          {
            subtopic,
            quizes: new Array<Quiz>()
          }
        );
      }
    } catch (err) {

    }
  }


  getQuizes(quizTopic: QuizTopicItem) {
    this.firestore.getCollection(
      {
        path: [DBNames.C_QUIZES],
        where: [new Where("topic", "==", quizTopic.title)],
        onComplete: (result) => {
          try {
            result.docs.forEach(
              doc => {
                let quizHeader = <Quiz>doc.data();
                for (let subtopicQuizHeader of this.subtopicQuizHeaders) {
                  if (quizHeader.subtopic == subtopicQuizHeader.subtopic) {
                    subtopicQuizHeader.quizes.push(quizHeader);
                  }
                }

              }
            );
          } catch (err) {

          }
        }
      }
    );

  }

  onQuizHeaderClick(quizHeader: Quiz) {
    if (AppComponent.isSignedIn && AppComponent.firebaseUser.life >= quizHeader.cost && !this.processingQuizPurchaseTransaction) {
      this.processingQuizPurchaseTransaction = true;
      this.updateUser(quizHeader);
    }
  }

  updateUser(quizHeader: Quiz) {
    let quizHistoryPath = [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id, DBNames.USER_QUIZ_HISTORY_COLLECTION, quizHeader.title];
    this.firestore.getDocument(
      {
        path: quizHistoryPath,
        onComplete: (result) => {

          var quizHistoryBatch: BatchOperation<UserQuizHistory>;
          if (result.exists) {
            quizHistoryBatch = new BatchOperation(
              "update",
              quizHistoryPath,
              <UserQuizHistory>{
                completed: false
              })
          } else {
            quizHistoryBatch = new BatchOperation(
              "create",
              quizHistoryPath,
              <UserQuizHistory>{
                completed: false,
                score: 0
              })

          }
          this.firestore.all(
            {
              operations: [
                quizHistoryBatch,
                new BatchOperation(
                  "update",
                  [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id],
                  {
                    life: this.firestore.increment(-quizHeader.cost)
                  })
              ],
              onComplete: () => {
                this.router.navigate([`view/quiz/${quizHeader.title}`]);
              },
              onFail: (err) => {
                this.processingQuizPurchaseTransaction = false;
              }
            }
          );
        }


      }
    );


  }

  overallHighScoreState() {
    return QuizHighScoreViewState.OVERALL;
  }
}


