<div id="signIn" class="k-center-box">
  <div id="sign-in-box-content" class="k-center-box-content">
    <div *ngIf="getShow()" id="sign-in-content">
      <div *ngIf="isLoginState()">
        <img src="assets/images/icons/codible_256.png" style="width: 3em;" />
        <h3 class="title" style="margin-top: .3em;">Codeible</h3>
        <input #emailInput placeholder="Email" (keyup.enter)="onSignInClick(emailInput, passwordInput)"
          style="margin-top: 1.3em;" />
        <input #passwordInput placeholder="Password" type="password"
          (keyup.enter)="onSignInClick(emailInput, passwordInput)" style="margin-top: 1.3em;" />

        <div style="display: grid; grid-template-columns: 1fr 1fr; margin-top: 1.3em;">
          <div></div>
          <button (click)="onSignInClick(emailInput, passwordInput)">Sign In</button>
        </div>
        <div style="margin-top: 1.3em;">
          <img (click)="onProviderSignInClick('Google')" class="provider-icons"
            src="assets/images/icons/google_circle.png">
          <img (click)="onProviderSignInClick('Yahoo')" class="provider-icons"
            src="assets/images/icons/yahoo-circle.png">
          <img (click)="onProviderSignInClick('Github')" class="provider-icons"
            src="assets/images/icons/github-circle.png">
          <!--<img (click)="onProviderSignInClick('facebook')" class="provider-icons" src="assets/images/icons/facebook_circle.png">-->
          <!--<img (click)="onProviderSignInClick('twitter')" class="provider-icons" src="assets/images/icons/twitter_circle.png">-->
        </div>
        <p (click)="onNewUserClick()">New User? Click here to Sign Up!</p>
        <p (click)="onForgotPasswordClick()">Forgot password? Click here!</p>

      </div>
      <div *ngIf="isRegisterState()">
        <h2 class="title">Create Account</h2>
        <input #sEmailInput placeholder="Email" style="margin-top: 1.3em;" />
        <input #sPasswordInput placeholder="Password" type="password" style="margin-top: 1.3em;" />
        <input #sConfirmPasswordInput placeholder="Password" type="password" style="margin-top: 1.3em;" />
        <div>
          <input #policyCheckBox type="checkbox" id="policyCheckBox" name="policyCheckBox"
            style="display: inline-block; width: 1em; height: 1em; box-shadow: none; padding: 0; margin: 0 1em 0 0;">
          <p style="display: inline-block;" (click)="onGoToPolicyClick()">I agree to the policies.</p>
        </div>

        <div style="display: grid; grid-template-columns: 1fr 1fr;">
          <div></div>
          <button (click)="onSignUpClick(sEmailInput, sPasswordInput, sConfirmPasswordInput, policyCheckBox)">Sign
            Up</button>
        </div>
        <p (click)="onOldUserClick()">Already have an account? Click here!</p>
      </div>

      <div *ngIf="isResetState()" >
        <h2 class="title">Reset My Password</h2>
          <input style="margin-top: 1.3em;" #fpEmailInput placeholder="Email" />
          <button style="margin-top: 1.3em;" (click)="onResetPasswordClick(fpEmailInput)">Reset</button>
          <p style="text-align: right; margin-top: 1.3em;" (click)="onIGotItClick()">I got it. Go back.</p>
      </div>
    </div>


  </div>
</div>

<h1 id="sign-in-text" (click)="onSignInTextClickLocal()">{{getSignInText()}}</h1>
<!--

    <button (click)="onSignInClick()" id="signInButton">Sign In</button>
  <div *ngIf="show">
  </div>
-->