import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MetatagService {
  readonly DEFAULT_KEYWORDS = "HTML, CSS, JavaScript, Python, TypeScript, Java, Kotlin, How to, Credit Card, SQL, Codeible, Free Tutorials, Free Courses, Node, NodeJS, Angular, Firebase, Firestore, Cloud Functions, Authentication, Google Maps, Visual Studio Code, FirebaseTS, FirebaseJS, Android, Android Firebase, Cloud Messaging";
  constructor(private title: Title, private meta: Meta, private router: Router) { }
  setMetaTags(config?: {
    title?: string,
    description?: string,
    image?: string
  }) {
    let url = `https://codeible.com${this.router.url}`;
    let description = config.description + " - Codeible";

    // Set title
    this.title.setTitle(config.title + " - Codeible");
  
    // Google
    this.meta.updateTag({ name: 'description', content: config.description +  " - Codeible"});
    this.meta.updateTag({ name: 'keywords', content: this.DEFAULT_KEYWORDS + `, ${config.title}` });

    // Twitter
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: `@codeible` });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: description});
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    // Facebook and other social sites
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: `Codeible` });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: description});
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: url});
    //this.meta.updateTag({ property: 'fb:app_id', content: `your-facebook-app-id` });
  }
}
