import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  title = "Account Policy";
  desc = "By signing up and obtaining an account for this site, you are agreeing to, that we have absolute control and onwership of your account. We can terminate the account at anytime.";
 
  constructor() { }

  ngOnInit() {
  }

}
