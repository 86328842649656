import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { AppComponent } from 'src/app/app.component';
import { DBNames } from 'src/app/classes/DBNames';
import { Tutorial } from 'src/app/classes/TutorialItem';
import { MetatagService } from 'src/app/services/metatag.service';


@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.css']
})
export class BookmarksComponent implements OnInit {
  firestore: FirebaseTSFirestore;
  tutorials: Tutorial [] = new Array<Tutorial>();
  shouldShowTutorialContents = false;
  selectedTutorial: Tutorial;
  readonly BOOKMARK_LISTENER_NAME = "BOOKMARK_LISTENER_1";
  
  constructor(private meta: MetatagService, @Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient) { 
    if(isPlatformBrowser(this.platformId)) {
      this.firestore = new FirebaseTSFirestore();
      AppComponent.isSignedInSubscription.subscribe(
        isSignedIn => {
          if(isSignedIn) {
            let userId = AppComponent.firebaseUser.id;
            meta.setMetaTags(
              {
                title: "Bookmarks",
                description: "View your bookmarks. Must be signed in.",
                image: "https://codeible.com/assets/images/icons/codible_256.png"
              }
            );
            this.firestore.listenToCollection(
              {
                name: this.BOOKMARK_LISTENER_NAME,
                path: [DBNames.USERS_COLLECTION, userId, DBNames.USERS_BOOKMARKS_COLLECTION],
                where: [],
                onUpdate: (result) => {
                  this.tutorials = [];
                  result.docs.forEach(
                    e => {
                      let tutorial = <Tutorial>e.data();
                      tutorial.id = e.id;
                      this.tutorials.push(tutorial);
                    }
                  );
                }
              }
            );
          } else {
            //this.firestore.stopListeningTo(this.BOOKMARK_LISTENER_NAME);
          }
        }
      );
    }
   
  }

  ngOnInit() {
  }

  isSignedIn(){
    return AppComponent.isSignedIn;
  }

  onDeleteBookmarkClick(tutorial: Tutorial){
    if(this.isSignedIn()) {
      this.firestore.delete(
        {
          path: [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id, DBNames.USERS_BOOKMARKS_COLLECTION, tutorial.id]
        }
      );
    }

  }

  onCloseTutorialContentClick(){
    this.shouldShowTutorialContents = false;
  }

  onBookmarkItemClick(tutorial: Tutorial) {
    this.shouldShowTutorialContents = true;
    this.onTutorialClick(tutorial);
  }
  onTutorialClick(tutorial: Tutorial){
    let firestore = new FirebaseTSFirestore();
      firestore.getDocument(
        {
          path: [DBNames.C_TUTORIALS, tutorial.id, DBNames.C_TUTORIAL_HEADER_TUTORIAL_CONTENT, DBNames.D_TUTORIAL_HEADER_TUTORIAL_CONTENT_DOCUMENT],
          onComplete: result => {
            let t = <Tutorial>result.data();
            t.id = result.id;
            this.selectedTutorial = t;
          }
        }
      );
    
  }
}
