import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { Subscription } from 'rxjs';
import { FirebaseTSAuth } from 'firebasets/firebasetsAuth/firebaseTSAuth';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  static show: boolean = false;
  showSignIn = true;
  showForgotPassword = false;
  isSignedInSub: Subscription;
  static isSignedIn = false;
  static auth: FirebaseTSAuth;
  authState: AuthState = AuthState.LOGIN;


  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object) { 
    SignInComponent.auth = new FirebaseTSAuth();
  }

  ngOnInit() {
    if(isPlatformBrowser(this.platformId)){
      this.isSignedInSub = AppComponent.isSignedInSubscription.subscribe(
        signedIn => {
          SignInComponent.isSignedIn = signedIn;
          SignInComponent.hideContent();
        }
      );
    }
  }

  ngOnDestroy(){
    try{
      this.isSignedInSub.unsubscribe();
    } catch (err) {}
  }
  onGoToPolicyClick(){
    this.router.navigate(['policy']);
  }
  public static onSignInTextClick(){
    if(this.isSignedIn){
      this.auth.signOut();
    } else {
      if(SignInComponent.show){
        this.hideContent();
      } else {
        this.showContent();
      }
    }
  }

  onSignInTextClickLocal () {
    SignInComponent.onSignInTextClick();
  }
  onProviderSignInClick(provider: "Google" | "Yahoo" | "Facebook" | "Apple" | "Github" | "Twitter" | "Microsoft") {
    SignInComponent.auth.signInWith(
      {
        provider
      }
    );
  }
  onSignInClick(emailInput: HTMLInputElement, passwordInput: HTMLInputElement){
    const email = emailInput.value;
    const password = passwordInput.value;
    SignInComponent.auth.signInWith(
      {
        email,
        password
      }
    ).catch(
      err => {
        alert(err.message);
      }
    );
  }


  onSignUpClick(sEmailInput: HTMLInputElement, sPasswordInput: HTMLInputElement, sConfirmPasswordInput: HTMLInputElement, policyCheckBox: HTMLInputElement){
    const email = sEmailInput.value;
    const password = sPasswordInput.value;
    const confirmPassword = sConfirmPasswordInput.value;
    const acceptedAgreement = policyCheckBox.checked;

    
    if(!acceptedAgreement){
      alert("You need to agree to our policies.");
    } else if (!(password.length >= 6)){
      alert("The password must be at least 6 characters long.");
    } else if (password !== confirmPassword) {
      alert("Passwords do not match.");
    } else {
      SignInComponent.auth.createAccountWith(
        {
          email,
          password,
          onComplete: (uc) => {

          }
        }
      ).catch(
        err => {
          alert(err.message);
        }
      );
    }
  }
  onResetPasswordClick(fpEmailInput: HTMLInputElement) {
    const email = fpEmailInput.value;
    
    if(email.length >= 5){
      SignInComponent.auth.sendPasswordResetEmail(
        {
          email,
          onComplete: (err) => {
            alert(err);
            this.showForgotPassword = false;
          }
        }
      );
    }
  }
  static hideContent(){
    SignInComponent.show = false;
    const signInBackground = <HTMLDivElement>document.getElementById("signIn");
    signInBackground.style.transform = "translate(125px, 125px)";
    signInBackground.style.borderRadius = "50%";
    signInBackground.style.width = "250px";
    signInBackground.style.height = "250px";
    signInBackground.style.backgroundColor = "rgb(255, 255, 0)";
    signInBackground.style.border = "3px dashed white";
  }
  static showContent(){
    SignInComponent.show = true;
    const signInBackground = <HTMLDivElement>document.getElementById("signIn");
    signInBackground.style.transform = "translate(0px, 0px)";
    signInBackground.style.borderRadius = "0";
    signInBackground.style.width = "100vw";
    signInBackground.style.height = "100vh";
    signInBackground.style.backgroundColor = "#d27eff";
    signInBackground.style.border = "none";
  }

  getSignInText(){
    if(SignInComponent.isSignedIn) {
      return "Sign Out";
    } else {
      if(SignInComponent.show) {
        return "Close";
      } else {
        return "Sign In";
      }
    }
    
  }

  onNewUserClick(){
    this.authState = AuthState.REGISTER;
  }

  onOldUserClick(){
    this.authState = AuthState.LOGIN;
  }
  onForgotPasswordClick(){
    this.authState = AuthState.RESET
  }

  onIGotItClick() {
    this.authState = AuthState.LOGIN;
  }

  getIsSignedIn(){
    return SignInComponent.isSignedIn;
  }

  getShow(){
    return SignInComponent.show;
  }

  isLoginState(){
    return this.authState == AuthState.LOGIN;
  }
  isRegisterState(){
    return this.authState == AuthState.REGISTER;
  }
  isResetState(){
    return this.authState == AuthState.RESET;
  }
}

export enum AuthState {
  LOGIN,
  RESET,
  REGISTER
}