
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { AppComponent } from 'src/app/app.component';
import { DBNames } from 'src/app/classes/DBNames';



@Component({
  selector: 'app-tutorial-page',
  templateUrl: './tutorial-page.component.html',
  styleUrls: ['./tutorial-page.component.css']
})
export class TutorialPageComponent implements OnInit {
  @Input() tutorial: any;
  @Input() contentArray: any | string [];
  firestore: FirebaseTSFirestore;

  constructor(private router: Router, private _snackBar: MatSnackBar, @Inject(PLATFORM_ID) private platform: Object) { 
    this.firestore = new FirebaseTSFirestore();
  } 

  ngOnInit() {

  }

  isLoggedIn(){
    return AppComponent.isSignedIn;
  }

  isCreator(){
    try{
      return AppComponent.isSignedIn && AppComponent.firebaseUser.isAdmin() && (this.tutorial.id? AppComponent.firebaseUser.id == this.tutorial.creatorId.stringValue: true);
    } catch (err) {

    }
    return false;
  }

  onEditClick(){
    if(this.isCreator()) {
      this.router.navigate(['create/tutorial/' + this.tutorial.id]);
    }
  }

  onBookmarkClick(){

    let isTutorial = !this.tutorial.authors.stringValue;
    if(this.isLoggedIn) {
      let user = AppComponent.firebaseUser;
      this.firestore.create(
        {
          path: [DBNames.USERS_COLLECTION, user.id, DBNames.USERS_BOOKMARKS_COLLECTION, this.tutorial.id],
          data: {
            authors: isTutorial? this.tutorial.authors: this.tutorial.authors.stringValue,
            cost: isTutorial? this.tutorial.cost: this.tutorial.cost.stringValue,
            creatorId: isTutorial? this.tutorial.creatorId: this.tutorial.creatorId.stringValue,
            dateCreated: isTutorial? this.tutorial.dateCreated: this.tutorial.dateCreated.timestampValue,
            description: isTutorial? this.tutorial.description: this.tutorial.description.stringValue,
            id: this.tutorial.id,
            tags: isTutorial? this.tutorial.tags: this.tutorial.tags.arrayValue.values,
            title: isTutorial? this.tutorial.title: this.tutorial.title.stringValue
          },
          onComplete: () => {
            this._snackBar.open("Bookmark added. To view your bookmarks, go to Account > Bookmarks.", 'Close', {
              duration: 10000,
            });
          }
        }
      );
    }
  }

  getFacebookRedirectUrl(){
    
    if(isPlatformBrowser(this.platform)) {
      return `https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=${encodeURI(window.location.href)}&display=popup&ref=plugin&src=share_button`;

    }
    return "";
  }

  getTwitterRedirectUrl(){
    
    if(isPlatformBrowser(this.platform)) {
      return `https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&url=${encodeURI(window.location.href)}`;

    }
    return "";
  }
}
