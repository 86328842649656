import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirebaseTSAuth } from 'firebasets/firebasetsAuth/firebaseTSAuth';
import { FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { DBNames } from './classes/DBNames';
import { IFirebaseUser, FirebaseUser } from './classes/FirebaseUser';
import { environment } from 'src/environments/environment';
import { FirebaseTSApp } from 'firebasets/firebasetsApp/firebaseTSApp';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  shouldShowMainSideNavbar = false;
  shouldShowAccountPopup = false;
  shouldShowAccountSidePopup = false;
  navbarTitle: HTMLHeadingElement;
  readonly THREE_HOURS_IN_SECONDS = 10800;
  readonly ONE_HOUR_30_MINS_IN_SECONDS = 5400;
  readonly THIRTY_MINUTES = 1800;
  readonly MAX_PLAYER_LIFE = 5;
  codeibleCounter = this.THIRTY_MINUTES;


  private static isSignedInObserver: BehaviorSubject<boolean>;
  public static isSignedInSubscription: Observable<boolean>;
  public static changeIsSignedInValue(isSignedIn: boolean) {
    AppComponent.isSignedInObserver.next(isSignedIn);
  }
  public static isSignedIn = false;

  private auth: FirebaseTSAuth;
  private firestore: FirebaseTSFirestore;
  private readonly FIRESTORE_USER_LISTENER_NAME = "FIRESTORE_USER_LISTENER_NAME";
  public static firebaseUser: FirebaseUser;
  showConsole = false;
  showInitialSignup = false;

  playerLife = [];
  maxPlayerLifeContainer = [0, 0, 0, 0, 0];


  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if(isPlatformBrowser(platformId)) {
      AppComponent.isSignedInObserver = new BehaviorSubject<boolean>(false);
      AppComponent.isSignedInSubscription = AppComponent.isSignedInObserver.asObservable();
      this.initAuth();
      this.initFirestore();
      this.subscriptTosignInState();
      this.setTimer();
    }
  }

  convertCodeibleCounterToTimer() {
    let hours = this.codeibleCounter / 3600;
    let minutes = Math.floor(((this.codeibleCounter / 60)) % 60);
    let seconds = this.codeibleCounter % 60;
    return this.playerLife.length >= this.MAX_PLAYER_LIFE ? "00:00" : `${this.addZeros(minutes.toString())}:${this.addZeros(seconds.toString())}`;
    //return this.playerLife.length >= this.MAX_PLAYER_LIFE ? "0:00:00" : `${Math.floor(hours)}:${this.addZeros(minutes.toString())}:${this.addZeros(seconds.toString())}`;
  }
  addZeros(str: String) {
    if (str.length < 2) {
      return "0" + str;
    } else {
      return str;
    }
  }

  ngOnInit() {

  }


  setTimer() {
    setInterval(() => {
      if (this.playerLife.length < this.MAX_PLAYER_LIFE && this.isSignedIn()) {
        this.codeibleCounter -= 1;
        if (this.codeibleCounter <= 0) {
          this.codeibleCounter = this.THIRTY_MINUTES;
          this.firestore.update(
            {
              path: [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id],
              data: {
                life: this.firestore.increment(1)
              }
            }
          );
        }
      } else {
        this.codeibleCounter = this.THIRTY_MINUTES;
      }
    }, 1000);
  }

  getUserLife(firebaseUser: FirebaseUser) {
    this.playerLife = [];
    let playerLifeInDatabase = firebaseUser.life;
    let mustHaveLife = playerLifeInDatabase? playerLifeInDatabase : 0;
    let clamper = mustHaveLife >= this.MAX_PLAYER_LIFE? this.MAX_PLAYER_LIFE: mustHaveLife;
    for (let i = 0; i < clamper; i++) {
      this.playerLife.push(i);
    }
    
  }


  onShowAccountPopupSideNavClick() {
    this.shouldShowAccountSidePopup = this.shouldShowAccountSidePopup? false: true;
  }
  onAccoutPopupSideItemClick() {
    this.shouldShowAccountSidePopup = false;
    this.shouldShowMainSideNavbar = false;
  }
  onShowAccountPopup() {
    this.shouldShowAccountPopup = this.shouldShowAccountPopup ? false : true;
  }
  onAccountPopupItemClick() {
    this.shouldShowAccountPopup = false;
  }
  onShowSideNavbarClick() {
    this.shouldShowMainSideNavbar = this.shouldShowMainSideNavbar ? false : true;
  }

  onMainSideNavbarItemClick() {
    this.shouldShowMainSideNavbar = false;
  }


  initAuth() {
    this.auth = new FirebaseTSAuth();
    this.auth.listenToSignInStateChanges(
      user => {
        this.auth.checkSignInState(
          {
            whenSignedIn: (user) => {
              this.listenToFirestoreUserData();
            },
            whenSignedOut: (user) => {
              this.stopListeningToFirestoreUserData();
              AppComponent.changeIsSignedInValue(false);
              AppComponent.firebaseUser = null;
              this.showConsole = false;
              this.showInitialSignup = false;
            }
          }
        );
      }
    );
  }
  initFirestore() {
    this.firestore = new FirebaseTSFirestore();

  }

  listenToFirestoreUserData() {
    const uid = this.auth.getAuth().currentUser.uid;
    this.firestore.listenToDocument(
      {
        name: this.FIRESTORE_USER_LISTENER_NAME,
        path: [DBNames.USERS_COLLECTION, uid],
        onUpdate: (result) => {
          if (result.exists) {
            let iFirebaseUserData = <IFirebaseUser>result.data();
            this.resetTimerIfGainedLife(iFirebaseUserData);
            AppComponent.firebaseUser = new FirebaseUser(iFirebaseUserData);
            AppComponent.firebaseUser.id = uid;
            this.getUserLife(AppComponent.firebaseUser);
            this.showConsole = AppComponent.firebaseUser.isAdmin();
            this.showInitialSignup = AppComponent.firebaseUser.name ? false : true;
            AppComponent.changeIsSignedInValue(true);

          } else {
            this.firestore.create<IFirebaseUser>(
              {
                path: [DBNames.USERS_COLLECTION, uid],
                data: {
                  accountType: environment.FREE_USER,
                  life: 0,
                  lifeTimestamp: FirebaseTSApp.getFirestoreTimestamp()
                },
                onComplete: (docId) => {
                  AppComponent.changeIsSignedInValue(true);
                }
              }
            );
          }
        }
      }
    );
  }

  resetTimerIfGainedLife(firebaseUser: IFirebaseUser){
    try {
      if(AppComponent.firebaseUser.life) {
        let previousLife = AppComponent.firebaseUser.life;
        if(firebaseUser?.life  > previousLife) {
          this.codeibleCounter = this.THIRTY_MINUTES;
        }
      }
    } catch (err) {}
    
  }
  stopListeningToFirestoreUserData() {
    this.firestore.stopListeningTo(this.FIRESTORE_USER_LISTENER_NAME);
  }

  subscriptTosignInState() {
    AppComponent.isSignedInSubscription.subscribe(
      isSignedIn => {
        AppComponent.isSignedIn = isSignedIn;
      }
    );
  }

  getAccountStatus() { 
    if (AppComponent.isSignedIn) {
      return AppComponent.isAdmin() ? "ADMIN" : "User";
    } else {
      return "Guest";
    }
  }
  isSignedIn() {
    return AppComponent.isSignedIn ? AppComponent.isSignedIn : false;
  }
  onSignoutClick(){
    this.shouldShowAccountPopup = false;
    this.auth.signOut();
  }

  public getUserName(){
    return AppComponent.firebaseUser?.name? AppComponent.firebaseUser.name: "";  
  }

  public static isAdmin() {
    try {
      return AppComponent?.firebaseUser?.accountType == environment.ADMIN; 
    } catch (err) {
      return false;
    }
  }

  public static isPremiumUser() {
    try {
      return AppComponent.firebaseUser.accountType == environment.PREMIUM_USER;
    } catch (err) {
      return false;
    }
  }
  public static isProUser() {
    try {
      return AppComponent.firebaseUser.accountType == environment.PRO_USER;
    } catch (err) {
      return false;
    }
  }

  public static isLibraryEnabled() {
    return AppComponent.isAdmin() || AppComponent.isPremiumUser() || AppComponent.isProUser();
  }

  isLibraryEnabled() {
    return AppComponent.isLibraryEnabled();
  }
}
