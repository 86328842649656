import { Component, OnInit } from '@angular/core';
import { BatchOperation, FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { AppComponent } from 'src/app/app.component';
import { DBNames } from 'src/app/classes/DBNames';
import { Question, QuestionType, Quiz, QuizTopicItem, ValueQuizTopicDocument} from 'src/app/classes/QuizItems';

@Component({
  selector: 'app-create-quiz',
  templateUrl: './create-quiz.component.html',
  styleUrls: ['./create-quiz.component.css']
})
export class CreateQuizComponent implements OnInit {


  quizTopicItens: QuizTopicItem[];
  topics: string [];
  subtopics: string [];
  selectedTopic: string;
  selectedSubtopic: string;
  questions: Question [] = [];


  private firestore: FirebaseTSFirestore;
  constructor() { 
    this.firestore = new FirebaseTSFirestore();
    this.getQuizTopics();
  }

  ngOnInit(): void {
  }

  isEligibleToCreate(){
    return AppComponent.isSignedIn && (AppComponent.firebaseUser.isAdmin());
  }

  

  createQuiz(titleInput: HTMLInputElement, costInput: HTMLInputElement){
    let title = titleInput.value;
    let cost = costInput.value;
    this.firestore.all(
      {
        operations: [
          new BatchOperation(
            "create", 
            [DBNames.C_QUIZES, title], 
            <Quiz>{
              title,
              cost: Number.parseInt(cost),
              topic: this.selectedTopic,
              subtopic: this.selectedSubtopic
            }),
            new BatchOperation(
              "create",
              [DBNames.C_QUIZES, title, DBNames.C_QUIZ_QUESTIONS, DBNames.D_QUIZ_QUESTIONS],
              {
                questions: this.questions
              }
            )
        ],
        onComplete: () => {
          alert("Quiz Created");
        }
      }
    );

  }

  onAddMultipleChoiceClick(){
    this.questions.push(
      {
        type: QuestionType.MULTIPLE_CHOICE
      }
    );
  }

  onAddShortAnswerClick(){
    this.questions.push(
      {
        type: QuestionType.SHORT_ANSWER
      }
    );
  }

  getQuizTopics(){
    this.quizTopicItens = new Array<QuizTopicItem>();
    this.topics = new Array<string>();

    this.firestore.getDocument(
      {
        path: [DBNames.C_VALUES, DBNames.VALUE_QUIZ_TOPIC],
        onComplete: (result) => {
          let quizTopicsDoc = <ValueQuizTopicDocument>result.data();
          quizTopicsDoc.list.forEach(
            topic => {
              this.quizTopicItens.push(topic);
              this.topics.push(topic.title);
            }
          );


        }
      }
    );
  }

  onTopicSelected(selectedTopic: string){
    for(let quizTopics of this.quizTopicItens) {
      if(quizTopics.title == selectedTopic) {
        this.subtopics = quizTopics.subtopics;
        break;
      }
    }
    this.selectedTopic = selectedTopic;
  }

  onSubtopicSelected(selectedSubtopic: string) {
    this.selectedSubtopic = selectedSubtopic;
  }



}
