<div class="quiz-high-score-view-container">
    <button mat-flat-button color="primary" class="high-score-view-toggle-button"(click)="onHideHighScoreViewClick()"><mat-icon>{{getHideIconText()}}</mat-icon></button>
    <mat-expansion-panel [ngClass]="{'quiz-high-score-view-active': showHighScoreView, 'quiz-high-score-view-idle': !showHighScoreView}" (click)="onQuizHighScoreViewClick()">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Codeible
            </mat-panel-title>
            <mat-panel-description>
                {{getTitle()}}
            </mat-panel-description>
        </mat-expansion-panel-header>
    
        <div class="quiz-high-score-view-content">
            <div class="high-score-view-content-item " *ngFor="let score of highScoreArray; let i = index;">
                <div style="text-align: center; display: flex; justify-content: flex-end;">
                    <img *ngIf="i == 0" class="high-score-rank-image" src="assets\images\icons\gold_crown.png" width="32px">
                    <img *ngIf="i == 1" class="high-score-rank-image" src="assets\images\icons\silver_crown.png" width="32px">
                    <img *ngIf="i == 2" class="high-score-rank-image" src="assets\images\icons\bronze_crown.png" width="32px">
                    {{i + 1}}
                </div>
                <div>
                    {{score?.name}}
                </div>
                <div>
                    {{score?.score}}
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</div>