<div id="create-group">
  <div class="creator-tool" (click)="getCourses()">
    <span style="font-size: 1.7em;" class="material-icons">bookmarks</span>
    <span>Add to course</span> 
  </div>
  
  
  <div *ngIf="show" id="create-group-drop-down">
    <h3>Add to</h3>
    <div *ngFor="let course of courses" class="group" (click)="onCourseClick(course)">
      <div class="group-item">
        <p>{{course?.title}}</p>
      </div>
    </div>
  </div>
</div>