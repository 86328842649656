import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BatchOperation, FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { DBNames } from 'src/app/classes/DBNames';

import { ScriptService } from 'src/app/services/script.service';
import { FirebaseTSStorage } from 'firebasets/firebasetsStorage/firebaseTSStorage';
import { FirebaseTSApp } from 'firebasets/firebasetsApp/firebaseTSApp';
import { Tutorial, TutorialContent, TutorialContentWithFile, TutorialHeader } from 'src/app/classes/TutorialItem';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-edit-tutorial',
  templateUrl: './edit-tutorial.component.html',
  styleUrls: ['./edit-tutorial.component.css']
})
export class EditTutorialComponent implements OnInit {

  phase = 0;
  /*
    1 = Textbox
    2 = Image
    3 = Video
  */
  selectionType = 0;
  hasImage = false;

  tutorialContentsWithFile: TutorialContentWithFile [] = [];

  creatorId: string;
  tutorial: Tutorial;
  tutorialContent: TutorialContent;
  // This is only used if the tutorial object is not repsent.
  temporaryContent: string [] = [];
  focus: boolean = false;
  isTitle: boolean = false;
  isSubtitle = false;
  isGist = false;
  initializedTextEditors = false;


  // Creator tool flags
  selectedInsertContent: string;

  

  public static readonly TYPE_IMG = "img";
  public static readonly TYPE_TXTBOX = "txtbox";
  public static readonly TYPE_VIDEO = "v";
  public static readonly TYPE_GIST = "gist";

  constructor(activatedRoute: ActivatedRoute, private router: Router, @Inject(PLATFORM_ID) private platformId: Object, private kTextEditorScript: ScriptService) { 
    activatedRoute.params.subscribe(
      params => {
        let tutorialId = params.id;
        
        if(tutorialId) {
          let firestore = new FirebaseTSFirestore();
          firestore.getDocument(
            {
              path: [DBNames.C_TUTORIALS, tutorialId],
              onComplete: (result) => {
                this.tutorial = <Tutorial>result.data();   
                this.tutorial.id = params.id;     
                firestore.getDocument(
                  {
                    path: [DBNames.C_TUTORIALS, tutorialId, DBNames.C_TUTORIAL_HEADER_TUTORIAL_CONTENT, DBNames.D_TUTORIAL_HEADER_TUTORIAL_CONTENT_DOCUMENT],
                    onComplete: (result) => {
                      this.tutorialContent = <TutorialContent>result.data();
                    }
                  }
                );
              }
            }
          );
        }
      }
    );
  }

  ngOnInit() {

  }

/*
  onDeleteClick(){
    if(this.tutorial) {
      let firestore = new FirebaseTSFirestore();
      firestore.delete(
        {
          from: [DBNames.C_TUTORIALS, this.tutorial.id],
          onComplete: () => {
            alert("Tutorial Deleted");
            this.router.navigate([""]);
          }
        }
      );
    }
  }
*/ 

  getContentArray(){
    return this.tutorialContent? this.tutorialContent.content: this.temporaryContent;
    //return this.tutorial? this.tutorial.content: this.temporaryContent;
  }
  onCostKeyup(tutorialCost: HTMLInputElement){
    tutorialCost.value = parseInt(tutorialCost.value) >= 0? parseInt(tutorialCost.value).toString(): "0";
  }

  onCancelAddTextClick(){
    this.phase = 0;
  }
  onConfirmAddTextClick(textboxEditor: HTMLInputElement){
    try{
      let textContent = textboxEditor.getAttribute("value");
      if(textContent && textContent.length > 0) {
        if(this.isGist) {
          this.getContentArray().push(
            `[${EditTutorialComponent.TYPE_GIST}]${textContent}`
          );
        } else {
          this.getContentArray().push(
            `[${EditTutorialComponent.TYPE_TXTBOX}${this.focus? `,style:focus`: ""}${this.isTitle? `,style:title`: ""}${this.isSubtitle? `,style:subtitle`: ""}]${textContent}`
          );
        }

        // Reset to defaults
        textboxEditor.setAttribute("value", "");
      }
    } catch (err) {
      console.log(err);
    }
  }
  onImageChange(imageInput: HTMLInputElement, imageDisplay: HTMLImageElement) {
    const fileReader = new FileReader();
    
    fileReader.onload = (onLoad) => {
    
      try{
        imageDisplay.src = fileReader.result.toString();
        this.hasImage = true;
      } catch (err){}
    }
    fileReader.readAsDataURL(imageInput.files[0]);
  }
  onConfirmAddImageClick(imageInput: HTMLInputElement, image: HTMLImageElement){
    if(!imageInput.value) return;
    try{
      let tutorialContentWithFile = <TutorialContentWithFile>{
        content: `[${EditTutorialComponent.TYPE_IMG}]${image.src}`,
        file: imageInput.files[0]
      }
      this.tutorialContentsWithFile.push(tutorialContentWithFile);
      this.getContentArray().push(
        tutorialContentWithFile.content
      );
      imageInput.value = "";
      image.src = "";
    } catch (err) {
      console.log(err);
    }
  }
  onSelectionItemClick(selectionType: number){
    this.selectionType = selectionType;
  }
  onFocusCheckboxClick(focus: HTMLInputElement){
    this.focus = focus.checked;
  }
  onIsTitleCheckboxClick(isTitleCB: HTMLInputElement) {
    this.isTitle = isTitleCB.checked;
  }
  onIsSubitleCheckboxClick(issubTitleCB: HTMLInputElement){
    this.isSubtitle = issubTitleCB.checked;
  }

  onIsGistCheckboxClick(isGistCB: HTMLInputElement){
    this.isGist = isGistCB.checked;
  }
  onInsertWithTutorialClick(content: string) {
    if(!this.selectedInsertContent) this.selectedInsertContent = content; 
    else {
      this.getContentArray().splice(this.getContentArray().indexOf(this.selectedInsertContent), 1);
      // Swap 
      let insertIndex = this.getContentArray().indexOf(content);
      this.getContentArray().splice(insertIndex, 0, this.selectedInsertContent);   
      // Reset selected insert content variable
      this.selectedInsertContent = null;
    }
  }
  // TODO: EDIT FEATURE
  onEditTutorialContentClick(contentContainer: HTMLElement, editTextbox: HTMLDivElement) {
    /*
    let {top, left, height} = contentContainer.getBoundingClientRect();
    editTextbox.style.position = "fixed";
    editTextbox.style.top = `${top + height}px`;
    editTextbox.style.left = `${left}px`;
    editTextbox.style.width = `${400}px`;
    */
  }
  onConfirmAddVideoClick(videoInput: HTMLInputElement){
    const url = videoInput.value;
    try{
      if(url.length < 5) return;

      if(this.isYoutubeLink(url)) {
        const videoIdString = url.split("watch?v=")[1];
        const filteredVideoIdString = videoIdString.substring(0, videoIdString.indexOf("&") > -1? videoIdString.indexOf("&"): videoIdString.length);
        if(filteredVideoIdString.length > 5) {
          this.getContentArray().push(
            `[${EditTutorialComponent.TYPE_VIDEO}]${filteredVideoIdString}`
          );
        
          videoInput.value = "";
        }
       
      }
    } catch (err) {

    }
  }
  isYoutubeLink(link: string){
    return link.match("youtube.com") || link.match("youtu.be.com");
  }

  onDeleteTutorialContentClick(content: string) {
    let contentIndex = this.getContentArray().indexOf(content);
    this.getContentArray().splice(contentIndex, 1);
  }
  hasPrivilege(){
    try{
      return AppComponent.isSignedIn && AppComponent.firebaseUser.isAdmin() && (this.tutorial? AppComponent.firebaseUser.id == this.tutorial.creatorId: true);
    } catch (err) {

    }
    return false;
  }

  isIndicatorPhase(){
    return this.phase == 0;
  }
  isSelectionPhase(){
    return this.phase == 1;
  }
  isResultPhase(){
    return this.phase == 2;
  }

  selectedTextbox(){
    return this.selectionType == 1;
  }
  selectedImage(){
    return this.selectionType == 2;
  }
  selectedVideo(){
    return this.selectionType == 3;
  }
  onIndicatorClick(){
    this.phase = 1;
  }
  onSelectionClick(){
    this.phase = 2;
  }


  onPreviewClick() {
    if(this.tutorial)
      this.router.navigate([`view/tutorial/${this.tutorial.id}`]);
    else 
      alert("Must save to create tutorial before you can preview.");
  }

  ngOnDestroy(){
    this.destroyTextEditorScript();
  }

  setupTextEditor(){
    if(this.initializedTextEditors) return;
    this.initializedTextEditors = true;
    this.destroyTextEditorScript();
    this.kTextEditorScript.set("../../../../assets/libs/KWMTextEditorMinified/KWMTextEditor.js");
    this.kTextEditorScript.run();
  }
  destroyTextEditorScript(){
    try{
      this.kTextEditorScript.destroy();
    } catch (err) {

    }
  }
  saveContentWithFiles(tutorialId: string){
    const storage = new FirebaseTSStorage();
    let uploadToStoragePromises = new Array<Promise<any>>();
    for(let contentsWithFile of this.tutorialContentsWithFile) {
      const fileName = `${EditTutorialComponent.TYPE_IMG} - ${contentsWithFile.file.name}${contentsWithFile.file.size}`;
      uploadToStoragePromises.push(storage.upload({
        uploadName: fileName,
        path: [DBNames.C_TUTORIALS, tutorialId, fileName],
        data: {
          data: contentsWithFile.file
        },
        onComplete: downloadUrl => {
          this.getContentArray()[this.getContentArray().indexOf(contentsWithFile.content)] = `[${EditTutorialComponent.TYPE_IMG}]${downloadUrl}`;
        }
      }));
    }
    return Promise.all(uploadToStoragePromises);
  }

  save(){
    const firestore = new FirebaseTSFirestore();
    const title = (<HTMLInputElement>document.getElementById("tutorial-title-inp")).value;
    const description = (<HTMLInputElement>document.getElementById("tutorial-description")).value;
    const authors = (<HTMLInputElement>document.getElementById("tutorial-authors")).value;
    const cost = (<HTMLInputElement>document.getElementById("tutorial-cost")).value;
    const tags = (<HTMLInputElement>document.getElementById("tutorial-tags")).value;
    if(title.length <= 0 || authors.length <= 0) {
      alert("Need to enter a Title and Author(s) for the tutorial.");
      return;
    }
    if(this.tutorial) {

      this.saveContentWithFiles(this.tutorial.id).then(
        () => {
          this.updateTutorial(
            firestore,
            title,
            description, 
            authors,
            cost,
            tags
            );
         /* firestore.update(
            {
              path: [DBNames.C_TUTORIALS, this.tutorial.id],
              data: {
                content: this.tutorial.content,
                title,
                description,
                authors,
                creatorId: AppComponent.firebaseUser.id,
                dateCreated: FirebaseTSApp.getFirestoreTimestamp(),
                cost,
                tags: tags.split(",")
              }
            }
          ).then(
            () => {
              alert("Tutorial saved.");
              this.tutorialContentsWithFile = [];
            }
          );*/
        }
      );

    } else {
      const generatedDocId = firestore.genDocId();
      this.saveContentWithFiles(generatedDocId).then(
        () => {
          this.createTutorial(
            firestore,
            title,
            description, 
            authors,
            cost,
            tags
            );
          /*firestore.create(
            {
              path: [DBNames.C_TUTORIALS, generatedDocId],
              data: {
                content: this.getContentArray(),
                title,
                description,
                authors,
                creatorId: AppComponent.firebaseUser.id,
                dateCreated: FirebaseTSApp.getFirestoreTimestamp(),
                cost: cost,
                tags: tags.split(",")
              }
            }
          ).then(
            () => {
              alert("Tutorial saved.");
              this.router.navigate(['create/tutorial/' + generatedDocId]);
              this.tutorialContentsWithFile = [];
            }
          );*/
        }
      );
      
    }
  }
  updateTutorial(firestore: FirebaseTSFirestore, title: string, description: string, authors: string, cost: string, tags: string){
    let updateTutorialBatch = new BatchOperation(
      "update",
      [DBNames.C_TUTORIALS, this.tutorial.id],
      <TutorialHeader>{
        title,
        description,
        authors,
        creatorId: AppComponent.firebaseUser.id,
        dateCreated: FirebaseTSApp.getFirestoreTimestamp(),
        cost: cost,
        tags: tags.split(",")
      }
    );
    let updateTutorialContentBatch = new BatchOperation(
      "update",
      [DBNames.C_TUTORIALS, this.tutorial.id, DBNames.C_TUTORIAL_HEADER_TUTORIAL_CONTENT, DBNames.D_TUTORIAL_HEADER_TUTORIAL_CONTENT_DOCUMENT],
      <TutorialContent>{
        content: this.getContentArray()
      }
    );
    firestore.all(
      {
        operations: [updateTutorialBatch, updateTutorialContentBatch],
        onComplete: () => {
          alert("Tutorial saved.");
          this.tutorialContentsWithFile = [];
        }
      }
    );
  }
  createTutorial(firestore: FirebaseTSFirestore, title: string, description: string, authors: string, cost: string, tags: string){
    const generatedDocId = firestore.genDocId();
    let createTutorialBatch = new BatchOperation(
      "create",
      [DBNames.C_TUTORIALS, generatedDocId],
      <TutorialHeader>{
        title,
        description,
        authors,
        creatorId: AppComponent.firebaseUser.id,
        dateCreated: FirebaseTSApp.getFirestoreTimestamp(),
        cost: cost,
        tags: tags.split(",")
      }
    );
    let createTutorialContentBatch = new BatchOperation(
      "create",
      [DBNames.C_TUTORIALS, generatedDocId, DBNames.C_TUTORIAL_HEADER_TUTORIAL_CONTENT, DBNames.D_TUTORIAL_HEADER_TUTORIAL_CONTENT_DOCUMENT],
      <TutorialContent>{
        content: this.getContentArray()
      }
    );
    firestore.all(
      {
        operations: [createTutorialBatch, createTutorialContentBatch],
        onComplete: () => {
          alert("Tutorial saved.");
        this.router.navigate(['create/tutorial/' + generatedDocId]);
        this.tutorialContentsWithFile = [];
        }
      }
    );
  }

  hasTutorial(){
    return this.tutorial;
  }

  onContentDropped(event: CdkDragDrop<string[]>){
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    console.log("DROPPED");
  }
}
