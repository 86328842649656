<div class="dropdown-selector" >
    <div class="dropdown-selector-toggle" (click)="onDropdownSelectorClick()">
        <span class="selector-toggle-text" class="ellipsis-text">{{getSelectorText()}}</span>
        <mat-icon class="dropdown-selector-toggle-indicator" aria-label="Dropdown Selector Status Indicator Icon">
            {{getSelectorIcon()}}</mat-icon>
    </div>
    <div *ngIf="isDropdownSelectorToggled" class="dropdown-selector-list" (click)="onDropdownSelectorClick()" >
        <div *ngFor="let dropdownItem of dropdownItemList" class="ellipsis-text selector-toggle-item"
            (click)="onDropdownItemClick(dropdownItem)">{{dropdownItem}} </div>
    </div>
</div>
