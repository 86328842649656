import { Component, Input, OnInit } from '@angular/core';
import { Question } from 'src/app/classes/QuizItems';

@Component({
  selector: 'app-add-short-answer',
  templateUrl: './add-short-answer.component.html',
  styleUrls: ['./add-short-answer.component.css']
})
export class AddShortAnswerComponent implements OnInit {
  @Input() question: Question;
  constructor() { }

  ngOnInit(): void {
  }

  onQuestionEnter(questionInput: HTMLInputElement){
    this.question.question = questionInput.value;
  }

  onDetailsEnter(details: HTMLTextAreaElement) {
    this.question.details = details.value;
  }

  onCorrectAnswerEnter(correctAnswer: HTMLInputElement){
    this.question.correctAnswer = correctAnswer.value;
  }

  onScoreEnter(scoreInput: HTMLInputElement) {
    this.question.score = Number.parseInt(scoreInput.value);
  }
}
