import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.css']
})
export class ConsoleComponent implements OnInit {
  show = false;
  consoleText: string;
  showConsoleButton = false;
  constructor(private router: Router) { 
    window.addEventListener("mousemove", (ev) => {
      if(ev.x < 35 && !this.showConsoleButton) {
        this.showConsoleButton = true;
      } else if(ev.x > 160 && this.showConsoleButton && !this.show) {
        this.showConsoleButton = false;
      }
    });

  }

  ngOnInit() {
    this.hideConsole();
  }

  onConsoleClick(){
    if(this.consoleText == "Close Console"){
      this.hideConsole();
    } else {
      this.showConsole();
    }
  }

  showConsole(){
    this.show = true;
    this.consoleText = "Close Console";
    const consoleDiv = <HTMLDivElement>document.getElementById("console");
    consoleDiv.style.width = "100vw";
  }

  hideConsole(){
    this.show = false;
    this.consoleText = "Console";
    const consoleDiv = <HTMLDivElement>document.getElementById("console");
    consoleDiv.style.width = "0vw";
  }

  onCreateTutorialClick(){
    this.router.navigate(["create/tutorial"]);
    this.hideConsole();
  }

  onCreateCourseClick(){
    this.router.navigate(["create/course"]);
    this.hideConsole();
  }

  onCreateQuizClick(){
    this.router.navigate(["create/quiz"]);
    this.hideConsole();
  }


}
