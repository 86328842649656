import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
//import { TutorialContent } from 'src/app/classes/TutorialContent';


@Component({
  selector: 'app-content-display',
  templateUrl: './content-display.component.html',
  styleUrls: ['./content-display.component.css']
})
export class ContentDisplayComponent implements OnInit {
 // @Input() contentItem: TutorialContent;
  @Input() editable?: boolean;
  //@Input() content?: TutorialContent [];
  edit = false;
  
  constructor() { 

  }

  ngOnInit() {

  }

  onDeleteClick(){
    //this.content.splice(this.content.indexOf(this.contentItem), 1);
  }

  onEditClick(){
    this.edit = this.edit? false: true;
  }

}
