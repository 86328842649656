
<div id="quiz-page">
    <app-dropdown [dropdownItemList]="topics" (selectedDropdownItem)="onTopicSelected($event)"></app-dropdown>
    <div class="subtopic" *ngFor="let subtopicQuizHeader of subtopicQuizHeaders">
        <div class="subtopic-title">{{subtopicQuizHeader?.subtopic}}</div>
        <div class="quizes">
            <div *ngFor="let quizes of subtopicQuizHeader?.quizes" class="quiz-header">
                <div style="display: flex; align-items: center;">
                    <img *ngIf="topicIcon" [src]="topicIcon" width="24px"
                        style="border-radius: 50%; padding: .5em; background-color: white; ">
                    <div style="color: white; margin-left: 2em;">{{quizes?.title}}</div>
                </div>
                <div class="quiz-header-footer">
                    <div class="quiz-header-footer-item"
                        style="display: flex; justify-content: center; align-items: center;">
                        <img src="assets\images\icons\codible_256.png" width="32px">
                        <span style="margin-left: .5em;"> X{{quizes?.cost}}</span>
                    </div>
                    <button mat-flat-button color="warn" (click)="onQuizHeaderClick(quizes)">Take Quiz</button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-quiz-high-score-view [state]="overallHighScoreState()"></app-quiz-high-score-view>
<app-footer></app-footer>