import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  title = "Cookies";
  bullet1 = "This site uses cookies to serve ads based on a user's prior visit to this or other sites.";
  bullet2 = "Google's use of advertising cookies enables it and its partners to serve ads to your users based on their visit to your sites and/or other sites on the Internet.";
  bullet3 = "Users may opt out of personalized advertising by visiting <a href='https://www.google.com/settings/ads'>Ads Setting</a>s. You can opt out of a third-party vendor's use of cookies for personalized advertising by visiting <a href='www.aboutads.info'>about ads</a>."
  group2 = "If you have not opted out of third-party ad serving, the cookies of other third-party vendors or ad networks may also be used to serve ads on your site, which should also be disclosed in your privacy policy in the following manner";
  bullet4 = "Notify your site visitors of the third-party vendors and ad networks serving ads on your site.";
  bullet5 = "Provide links to the appropriate vendor and ad network websites.";
  bullet6 = "Inform your users that they may visit those websites to opt out of the use of cookies for personalized advertising (if the vendor or ad network offers this capability). Alternatively, you can direct users to opt out of some third-party vendors’ uses of cookies for personalized advertising by visiting www.aboutads.info.";
  constructor() { }

  ngOnInit() {
  }
  setInnerHTML(element: HTMLElement, html: string) {
    element.innerHTML = html;
  }

}
