import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './2_x_x/pages/home/home.component';
import { CoursesComponent } from './2_x_x/pages/courses/courses.component';
import { CourseHeaderCreatorToolsComponent } from './2_x_x/tools/course-header-creator-tools/course-header-creator-tools.component';
import { TutorialContentComponent } from './2_x_x/tools/tutorial-content/tutorial-content.component';
import { EditTutorialComponent } from './2_x_x/pages/edit-tutorial/edit-tutorial.component';
import { ViewTutorialComponent } from './2_x_x/pages/view-tutorial/view-tutorial.component';
import { TutorialHeaderComponent } from './2_x_x/tools/tutorial-header/tutorial-header.component';

import { SignInComponent } from './2_x_x/tools/sign-in/sign-in.component';
import { FirebaseTSApp } from 'firebasets/firebasetsApp/firebaseTSApp';
import { environment } from 'src/environments/environment';
import { TopicComponent } from './tools/topic/topic.component';
import { PricingComponent } from './2_x_x/tools/pricing/pricing.component';
import { ExploreComponent } from './2_x_x/pages/explore/explore.component';
import { ConsoleComponent } from './2_x_x/tools/console/console.component';
import { TextboxComponent } from './pages/create-tutorial/tools/textbox/textbox.component';
import { ImageComponent } from './pages/create-tutorial/tools/image/image.component';
import { TextboxDisplayComponent } from './pages/create-tutorial/displays/textbox-display/textbox-display.component';
import { ContentDisplayComponent } from './pages/create-tutorial/displays/content-display/content-display.component';
import { ImageDisplayComponent } from './pages/create-tutorial/displays/image-display/image-display.component';
import { VideoComponent } from './pages/create-tutorial/tools/video/video.component';
import { VideoDisplayComponent } from './pages/create-tutorial/displays/video-display/video-display.component';
import { CreateCourseComponent } from './pages/create-course/create-course.component';
import { CourseHeaderComponent } from './2_x_x/tools/course-header/course-header.component';

import { ViewCourseComponent } from './2_x_x/pages/view-course/view-course.component';
import { ConfirmDialogComponent } from './2_x_x/tools/confirm-dialog/confirm-dialog.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { FooterComponent } from './2_x_x/tools/footer/footer.component';
import { CookiesComponent } from './pages/policy/cookies/cookies.component';
import { IntroComponent } from './pages/policy/intro/intro.component';
import { SecurityComponent } from './pages/policy/security/security.component';
import { ContactUsComponent } from './pages/policy/contact-us/contact-us.component';
import { InitialSignupComponent } from './2_x_x/tools/initial-signup/initial-signup.component';
import { AddToCourseComponent } from './2_x_x/tools/add-to-course/add-to-course.component';
import { AccountComponent } from './pages/policy/account/account.component';
import { TutorialPageComponent } from './2_x_x/tools/tutorial-page/tutorial-page.component';
import { BookmarksComponent } from './2_x_x/pages/bookmarks/bookmarks.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuizViewComponent } from './2_x_x/pages/quiz-view/quiz-view.component';
import { MultipleChoiceComponent } from './2_x_x/pages/quiz-view/tools/multiple-choice/multiple-choice.component';
import { QuizSubmitDialogComponent } from './2_x_x/pages/quiz-view/tools/quiz-submit-dialog/quiz-submit-dialog.component';
import { ShortAnswerComponent } from './2_x_x/pages/quiz-view/tools/short-answer/short-answer.component';
import { CreateQuizComponent } from './2_x_x/pages/create-quiz/create-quiz.component';
import { AddMultipleChoiceComponent } from './2_x_x/pages/create-quiz/tools/add-multiple-choice/add-multiple-choice.component';
import { AddShortAnswerComponent } from './2_x_x/pages/create-quiz/tools/add-short-answer/add-short-answer.component';
import { CourseFilesDownloadComponent } from './2_x_x/pages/course-files-download/course-files-download.component';
import { QuizesComponent } from './2_x_x/pages/quizes/quizes.component';
import { DropdownComponent } from './2_x_x/tools/dropdown/dropdown.component';
import { ViewQuizResultsComponent } from './2_x_x/pages/quiz-view/tools/view-quiz-results/view-quiz-results.component';
/*
Angular Material
*/

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuizHighScoreViewComponent } from './2_x_x/tools/quiz-high-score-view/quiz-high-score-view.component';
import { ProjectsComponent } from './2_x_x/pages/projects/projects.component';

import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { GoogleAdComponent } from './2_x_x/tools/google-ad/google-ad.component';
import { ToolsComponent } from './2_x_x/pages/tools/tools.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SignInComponent,
    TopicComponent,
    PricingComponent,
    ExploreComponent,
    ConsoleComponent,
    TextboxComponent,
    ImageComponent,
    TextboxDisplayComponent,
    ContentDisplayComponent,
    ImageDisplayComponent,
    VideoComponent,
    VideoDisplayComponent,
    CreateCourseComponent,
    CourseHeaderComponent,
    CoursesComponent,
    ViewCourseComponent,
    ConfirmDialogComponent,
    PolicyComponent,
    FooterComponent,
    CookiesComponent,
    IntroComponent,
    SecurityComponent,
    ContactUsComponent,
    InitialSignupComponent,
    CourseHeaderCreatorToolsComponent,
    TutorialContentComponent,
    EditTutorialComponent,
    ViewTutorialComponent,
    TutorialHeaderComponent,
    AddToCourseComponent,
    AccountComponent,
    TutorialPageComponent,
    BookmarksComponent,
    QuizViewComponent,
    MultipleChoiceComponent,
    QuizSubmitDialogComponent,
    ShortAnswerComponent,
    CreateQuizComponent,
    AddMultipleChoiceComponent,
    AddShortAnswerComponent,
    CourseFilesDownloadComponent,
    QuizesComponent,
    DropdownComponent,
    ViewQuizResultsComponent,
    QuizHighScoreViewComponent,
    ProjectsComponent,
    GoogleAdComponent,
    ToolsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    MatStepperModule,
    MatRadioModule,
    FormsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatIconModule,
    MatExpansionModule,
    MatTooltipModule,
    HttpClientModule,
    DragDropModule
  ],
  providers: [HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(){
    try {
      FirebaseTSApp.init(
        environment.firebaseConfig
      );
    } catch (err) {
      
    }
  }
}
