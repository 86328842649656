import { environment } from 'src/environments/environment';

export class FirebaseUser {
    accountType: string;
    id: string;
    name: string;
    life: number;
    lifeTimestamp: firebase.default.firestore.Timestamp;
    constructor(firebaseUser: IFirebaseUser){
        this.accountType = firebaseUser.accountType;
        this.name = firebaseUser.name;
        this.life = firebaseUser.life;
 
    }

    isAdmin(): boolean {
        return this.accountType == environment.ADMIN;
    }
}

export interface IFirebaseUser {
    accountType?: string;
    timestamp?: firebase.default.firestore.Timestamp;
    name?: string;
    life: number;
    lifeTimestamp: firebase.default.firestore.Timestamp;
}