import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BatchOperation, FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { DBNames } from 'src/app/classes/DBNames';
import { Question, QuestionType, Quiz, UserQuizHistory, QuizQuestionResult, QuizSubmitData} from 'src/app/classes/QuizItems';
import { MetatagService } from 'src/app/services/metatag.service';
import { QuizHighScoreViewState } from '../../tools/quiz-high-score-view/quiz-high-score-view.component';
import { QuizSubmitDialogComponent } from './tools/quiz-submit-dialog/quiz-submit-dialog.component';

@Component({
  selector: 'app-quiz-view',
  templateUrl: './quiz-view.component.html',
  styleUrls: ['./quiz-view.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        showError: true,
        displayDefaultIndicatorType: false
      }
    }
  ]
})
export class QuizViewComponent implements OnInit {
  readonly USER_QUIZ_HISTORY_LISTENER = "QUIZ_HISTORY_LISTENER";
  firestore = new FirebaseTSFirestore();
  questions: Question[];
  isSignedInSubscription: Subscription;
  ableToView = false;
  signedIn = false;
  quiz: Quiz;
  quizHistory: UserQuizHistory;
  quizId: string;
  shouldShowResults = false;


  constructor(private activatedRoute: ActivatedRoute, private scoreScreen: MatDialog, private router: Router, private meta: MetatagService, @Inject(PLATFORM_ID) private platformId: Object) {
    this.meta.setMetaTags(
      {
        "title": `Taking Quiz`,
        "description": `Taking a quiz....`,
        "image": "https://codeible.com/assets/images/icons/codible_256.png"
      }
    );

  }
  ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.isSignedInSubscription = AppComponent.isSignedInSubscription.subscribe(
        signedIn => {
          this.signedIn = signedIn;
          if (signedIn) {
            this.getRouterParams();
          }
        } 
      );
    }
  }

  getRouterParams() {
    this.activatedRoute.params.subscribe(
      params => {
        let id = params.id;
        this.quizId = id;
        this.meta.setMetaTags(
          {
            "title": `Quiz for ${this.quizId}`,
            "description": `Take the  ${this.quizId} quiz.`,
            "image": "https://codeible.com/assets/images/icons/codible_256.png"
          }
        );
        this.checkIfownedOrCompletedQuiz(id); 
      }
    );
  }

  checkIfownedOrCompletedQuiz(quizId: string) {
    this.firestore.stopListeningTo(this.USER_QUIZ_HISTORY_LISTENER);
    this.firestore.listenToDocument(
      {
        name: this.USER_QUIZ_HISTORY_LISTENER,
        path: [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id, DBNames.USER_QUIZ_HISTORY_COLLECTION, quizId],
        onUpdate: (result) => {
          if(result.exists) {
            this.quizHistory = <UserQuizHistory>result.data();
            let completed = this.quizHistory.completed;
            if (completed) {
              this.ableToView = false;
              this.getQuiz(quizId);
            } else {
              this.ableToView = true;
              this.getQuizQuestions(quizId);
            }
          } else {
            this.router.navigate(["quizes"]); 
          }

        }
      }
    );
  }
  onRetakeClick() {
    if (!this.quiz || AppComponent.firebaseUser.life < this.quiz.cost) return; 
    this.hideResults();
    this.firestore.all(
      {
        operations: [
          new BatchOperation(
            "update",
            [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id, DBNames.USER_QUIZ_HISTORY_COLLECTION, this.quiz.title],
            <UserQuizHistory>{
              completed: false
            }),
          new BatchOperation(
            "update",
            [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id],
            {
              life: this.firestore.increment(-this.quiz.cost)
            })
        ]
      }
    );
  }
  getQuiz(quizId: string) {
    this.firestore.getDocument(
      {
        path: [DBNames.C_QUIZES, quizId],
        onComplete: (result) => {
          this.quiz = <Quiz>result.data();
        }
      }
    );
  }

  getQuizQuestions(quizId: string) {
    this.firestore.getDocument(
      {
        path: [DBNames.C_QUIZES, quizId, DBNames.C_QUIZ_QUESTIONS, DBNames.D_QUIZ_QUESTIONS],
        onComplete: (result) => {
          this.questions = result.data().questions;
        }
      }
    );
  }



  ngOnDestroy() {
    try {
      this.isSignedInSubscription.unsubscribe();
    } catch (err) {

    }
    this.firestore.stopListeningTo(this.USER_QUIZ_HISTORY_LISTENER);

  }

  onSubmitClick = () => {
    if (!this.ableToView) return;
    let score = this.calculateScore();
    this.scoreScreen.open(QuizSubmitDialogComponent,
      {
        disableClose: true,
        data: <QuizSubmitData>{
          score: score.totalScore,
          quizId: this.quizId,
          maxScore: score.maxScore,
          questionResults: score.questionResults
        }
      });
  }

  calculateScore() {
    var totalScore = 0;
    var maxScore = 0;
    var questionResults: QuizQuestionResult [] = [];
    for (let question of this.questions) {
      let userAnswer = question.userAsnwer;
      let correctAnswer = question.correctAnswer;
      let score = question.score;
      maxScore += score;

      var isCorrect = false;

      switch (question.type) {
        case QuestionType.MULTIPLE_CHOICE:
          isCorrect = userAnswer == correctAnswer;
          totalScore += isCorrect ? score : 0;
          break;
        case QuestionType.SHORT_ANSWER:
          isCorrect = this.validateShortAnswerQuestionAnswer(userAnswer, correctAnswer);
          totalScore += isCorrect ? score : 0;
          break;
      }

      questionResults.push(
        {
          question: question.question,
          correct: isCorrect
        }
      );
    }
    return {totalScore, maxScore, questionResults};
  }

  validateShortAnswerQuestionAnswer(userAnswer: string, correctAnswer: string) {
    let finalAnswer = userAnswer.replace(/\s/g, '').toLowerCase();
    let removedSpacesFromCorrectAnswer = correctAnswer.replace(/\s/g, '').toLowerCase();
    return finalAnswer == removedSpacesFromCorrectAnswer;
  }

  onSeeResultsClick(){
    this.shouldShowResults = this.shouldShowResults? false: true;
  }

  hideResults = () => {
    this.shouldShowResults = false;
  }

  showResults = () => {
    this.shouldShowResults = true;
  }

  highScoreState(){
    return QuizHighScoreViewState.QUIZ
  }
}
 

 