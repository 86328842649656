import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {
  isDropdownSelectorToggled = false;
  _selectedDropdownItem: string = "";
  @Input() dropdownItemList: string [];
  @Output() selectedDropdownItem? = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }
  onDropdownSelectorClick(){
    this.isDropdownSelectorToggled = this.isDropdownSelectorToggled? false: true;
  }

  getSelectorText(){
    return this._selectedDropdownItem? this._selectedDropdownItem: "Select an option.";
  }

  getSelectorIcon(){
    return this.isDropdownSelectorToggled? "keyboard_arrow_down": "keyboard_arrow_right";
  }

  onDropdownItemClick(dropdownItem: string) {
    if(this._selectedDropdownItem != dropdownItem){
      this._selectedDropdownItem = dropdownItem;
      this.selectedDropdownItem.emit(dropdownItem);
    } 
      
  }

}
