import { Component, Input, OnInit } from '@angular/core';
import { Question } from 'src/app/classes/QuizItems';


@Component({
  selector: 'app-short-answer',
  templateUrl: './short-answer.component.html',
  styleUrls: ['./short-answer.component.css']
})
export class ShortAnswerComponent implements OnInit {

 // @Input() quizItem: ShortAnswer;
  @Input() questionNumber: number;
  @Input() question: Question;
  @Input() numberOfQuestions: number;
  @Input() sumitClick?: () => any;

  
  constructor() { }

  ngOnInit(): void {
    this.question.completed = false;
  }

  onSubmitClick() {
    this.sumitClick();
  }

  onNextClick() {

  }

  onAnswerEnter(answerInput: HTMLInputElement){
    this.question.userAsnwer = answerInput.value;
    this.question.completed = true;
  }

  isNotLastQuestion(){
    return this.questionNumber < this.numberOfQuestions - 1;
  }

}
