import { Component, OnInit, Input } from '@angular/core';
import { TextboxComponent } from '../../tools/textbox/textbox.component';

@Component({
  selector: 'app-textbox-display',
  templateUrl: './textbox-display.component.html',
  styleUrls: ['./textbox-display.component.css']
})
export class TextboxDisplayComponent implements OnInit {
  @Input() text: string;
  @Input() extractedStyles: string [];
  styles = false;
  constructor() { }

  ngOnInit() {

  }

  getStyles(textboxDisplay: HTMLImageElement){
    if(!this.styles){
      this.styles = true;
      try{
        TextboxComponent.setStylesOf(textboxDisplay, this.extractedStyles);
      } catch (err) {}
    }
  }
}
