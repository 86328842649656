import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { DBNames } from 'src/app/classes/DBNames';
import { IPurchasedItem } from 'src/app/classes/TutorialContent';
import { AppComponent } from 'src/app/app.component';
import { Subscription } from 'rxjs';
import { render } from 'creditcardpayments/creditCardPayments';
import { FirebaseTSApp } from 'firebasets/firebasetsApp/firebaseTSApp';

import { Course } from '../../tools/course-header/course-header.component';
import { Tutorial } from 'src/app/classes/TutorialItem';
import { MetatagService } from 'src/app/services/metatag.service';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';



@Component({
  selector: 'app-view-course',
  templateUrl: './view-course.component.html',
  styleUrls: ['./view-course.component.css']
})
export class ViewCourseComponent implements OnInit {

  currentSelectedTutorial: any;
  swapModeOn = false;
  swappingTutorial = false;
  showDeleteMessage = false;
  deletingTutorial = false;

  selectedTutorialToDelete: Tutorial;
  selectedTutorialToSwap: Tutorial;
  //selectedTutorial: Tutorial;
  selectedTutorial: any;
  //selectedTutorialContent: TutorialContent;
  selectedTutorialContent: any[];
  //course: Course;
  course: any = {};
  //tutorials: Tutorial[] = [];
  tutorials: any[] = [];
  isSignedInSub: Subscription;
  isSignedIn = false;
  userSignedInSubscription: Subscription;
  readonly PURCHASES_LISTENER = "VIEW_COURSE_USER_PURCHASES";
  purchased: boolean;
  firestore: FirebaseTSFirestore;

  retrievedUrlTutorialContents = false;

  constructor(private params: ActivatedRoute, private router: Router, private meta: MetatagService, private http: HttpClient, @Inject(PLATFORM_ID) private platformId: object) {
    this.setMetaTags();
    this.firestore = new FirebaseTSFirestore();

  }

  private setMetaTags() {
    try {
      let url = this.router?.url;
      let title = url?.split("title=")[1];
      this.meta.setMetaTags(
        {
          title: title ? title.replace(/%20/g, " ") : "Codeible Video Tutorials",
          description: title ? title.replace(/%20/g, " ") : "View a list of awersome video tutorials from Codeible.com",
          image: "https://codeible.com/assets/images/icons/codible_256.png"
        }
      );
    } catch (err) {

    }
  }

  ngOnInit() {

    this.getCourseWithHttp(this.params);


  }


  
  ngOnDestroy() {
    if (this.isSignedInSub) {
      this.isSignedInSub.unsubscribe();
    }
    try {
      this.firestore.stopListeningTo(this.PURCHASES_LISTENER);
    } catch (err) {

    }

  }
  getCourse(params: ActivatedRoute) {
    let sub = params.params.subscribe(
      params => {

        let firestore = new FirebaseTSFirestore();
        firestore.getDocument(
          {
            path: [DBNames.C_COURSES, params.id],
            onComplete: (result) => {
              let _course = <Course>result.data();
              this.meta.setMetaTags(
                {
                  title: _course.title,
                  description: _course.desc,
                  image: _course.imgUrl
                }
              );
              _course.id = result.id;
              this.course = _course;
              //this.renderPaypal();
              //this.listenToUserPurchases(_course.id);
              let tutorialListDocumentFromCoursesCollection = [DBNames.C_COURSES, _course.id, DBNames.C_COURSES_TUTORIAL_LIST, DBNames.D_COURSES_TUTORIAL_LIST_DOCUMENT];
              firestore.getDocument(
                {
                  path: tutorialListDocumentFromCoursesCollection,
                  onComplete: (result) => {
                    this.tutorials = result.data().tutorials;
                    this.goToURLTutorial(params.title);
                    sub.unsubscribe();
                  }
                }
              );
            }
          }
        );
      }
    );
  }

  getCourseWithHttp(params: ActivatedRoute) {
    let sub = params.params.subscribe(
      params => {
        let sub2 = this.http.get<any>(`https://firestore.googleapis.com/v1/projects/codeible/databases/(default)/documents/Courses/${params.id}`)
          .subscribe(
            result => {
              if(isPlatformServer(this.platformId)) {
                this.meta.setMetaTags(
                  {
                    title: result.fields.title.stringValue,
                    description: result.fields.desc.stringValue,
                    image: result.fields.imgUrl.stringValue
                  }
                );
              }


              this.course = result.fields;
              this.course.id = params.id;

              this.getTutorialListWithHttp(params.id, params.title);
              sub.unsubscribe();
            }
          );
      }
    );
  }

  getTutorialListWithHttp(courseId: string, title: string) {
    let sub = this.http.get<any>(`https://firestore.googleapis.com/v1/projects/codeible/databases/(default)/documents/Courses/${courseId}/Tutorials List/Tutorials`)
      .subscribe(
        result => {
          //console.log(result);
          //console.log(result.fields.tutorials.arrayValue.values);
          this.tutorials = result.fields.tutorials.arrayValue.values;

          this.goToURLTutorial(title);
        }
      );
  }

  goToURLTutorial(urlTutorialTitle: string) {
    if (urlTutorialTitle && !this.retrievedUrlTutorialContents) {
      for (let tutorial of this.tutorials) {
        if (tutorial.mapValue.fields.title.stringValue == urlTutorialTitle) {
          this.retrievedUrlTutorialContents = true;
          this.onTutorialClick(tutorial);
          break;
        }
      }
    } else if (!urlTutorialTitle && this.tutorials.length > 0) {
      this.onTutorialClick(this.tutorials[0]);
    }
  }
  renderPaypal() {
    if (!this.course) return;
    render(
      {
        id: "#course-payment",
        currency: "USD",
        value: `${this.course.cost ? Number.parseInt(this.course.cost) / 100 : 0}`,
        onApprove: (details) => {
          let firestore = new FirebaseTSFirestore;
          firestore.create(
            {
              path: [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id, DBNames.USERS_PURCHASED_COLLECTION, this.course.id],
              data: <IPurchasedItem>{
                timestamp: FirebaseTSApp.getFirestoreTimestamp()
              },
              onComplete: (docId) => {

              }
            }
          );
        }
      }
    );
  }
  listenToUserPurchases(courseId: string) {
    this.firestore.stopListeningTo(this.PURCHASES_LISTENER);
    this.userSignedInSubscription = AppComponent.isSignedInSubscription.subscribe(
      isSignedIn => {
        if (this.isSignedIn = isSignedIn) {
          this.firestore.listenToDocument(
            {
              name: this.PURCHASES_LISTENER,
              path: [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id, DBNames.USERS_PURCHASED_COLLECTION, courseId],
              onUpdate: (result) => {
                this.purchased = result.exists;
              }
            }
          );
        } else {
          this.firestore.stopListeningTo(this.PURCHASES_LISTENER);
        }
      }
    );
  }

  /*onTutorialClick(tutorial: Tutorial) {
    this.selectedTutorial = tutorial;
    if (this.seleectedTutorialContent) this.seleectedTutorialContent.content.length = 0;
    if (this.isEligibleToView) {
      this.router.navigate(['view/videotutorial/' + this.course.id, { title: tutorial.title }]);
      let firestore = new FirebaseTSFirestore();
      firestore.getDocument(
        {
          path: [DBNames.C_TUTORIALS, tutorial.id],
          onComplete: result => {
            let t = <Tutorial>result.data();
            t.id = result.id;
            this.currentSelectedTutorial = t;
            firestore.getDocument(
              {
                path: [DBNames.C_TUTORIALS, tutorial.id, DBNames.C_TUTORIAL_HEADER_TUTORIAL_CONTENT, DBNames.D_TUTORIAL_HEADER_TUTORIAL_CONTENT_DOCUMENT],
                onComplete: (result) => {
                  this.seleectedTutorialContent = <TutorialContent>result.data();
                }
              }
            );
            //document.getElementById("tutorial-display").scrollTo(0, 0);
          }
        }
      );
    }

  }*/

  /*getRouterLink(tutorial: any) {

    return [`../${this.course.id}`, { title: tutorial.mapValue.fields.title.stringValue }];
  }*/

  getRouterLink(tutorial: any) {
    return [`view/videotutorial/${this.course.id};title=${tutorial.mapValue.fields.title.stringValue}`];
  }
  onTutorialClick(tutorial: any) {
    this.selectedTutorial = tutorial;
    if (this.selectedTutorialContent) this.selectedTutorialContent.length = 0;
    if (this.isEligibleToView) {
      //this.router.navigate(['view/videotutorial/' + this.course.id, { title: tutorial.mapValue.fields.title.stringValue }]);
      let tutorialId = tutorial.mapValue.fields.id.stringValue;
      let sub = this.http.get<any>(`https://firestore.googleapis.com/v1/projects/codeible/databases/(default)/documents/${DBNames.C_TUTORIALS}/${tutorialId}`)
        .subscribe(
          t => {
            let location = t.name;
            let docId = location.substr(location.lastIndexOf("/") + 1);
            this.currentSelectedTutorial = t.fields;
            this.currentSelectedTutorial.id = docId;
            this.meta.setMetaTags(
              {
                title: this.currentSelectedTutorial.title.stringValue,
                description: this.currentSelectedTutorial.description.stringValue,
                image: this.course.imgUrl.stringValue
              }
            );
            //console.log(this.currentSelectedTutorial);
            let sub2 = this.http.get<any>(`https://firestore.googleapis.com/v1/projects/codeible/databases/(default)/documents/${DBNames.C_TUTORIALS}/${tutorialId}/${DBNames.C_TUTORIAL_HEADER_TUTORIAL_CONTENT}/${DBNames.D_TUTORIAL_HEADER_TUTORIAL_CONTENT_DOCUMENT}`)
              .subscribe(
                tutorialContent => {

                  this.selectedTutorialContent = tutorialContent.fields.content.arrayValue;
                  //console.log(tutorialContent);
                }
              );
          }
        );

    }
    if(isPlatformBrowser(this.platformId)){
      document.getElementById("tutorial-display").scrollTo(0, 0);
    }
  }
  /*isSelectedTutorial(tutorial: Tutorial) {
    return this.selectedTutorial ? tutorial.title == this.selectedTutorial.title : false;
  }*/
  isSelectedTutorial(tutorial: any) {
    return this.selectedTutorial ? tutorial?.mapValue.fields.title.stringValue == this.selectedTutorial.mapValue.fields.title.stringValue : false;
  }
  onNextButtonClick() {
    var currentSelectedTutorialIndex = 0;
    for (let i = 0; i < this.tutorials.length; i++) {
      let tutorialTitle = this.tutorials[i].mapValue.fields.title.stringValue;
      if (tutorialTitle == this.currentSelectedTutorial.title.stringValue) { currentSelectedTutorialIndex = i; break; }

    }
    let NEXT_TUTORIAL_INDEX = currentSelectedTutorialIndex + 1;
    this.onTutorialClick(this.tutorials[NEXT_TUTORIAL_INDEX >= this.tutorials.length ? 0 : NEXT_TUTORIAL_INDEX]);
    document.getElementById("tutorial-display").scrollTo(0, 0);

  }

  isEligibleToView() {
    try {
      if (this.course.cost && Number.parseInt(this.course.cost) > 0) {
        try {
          return this.isSignedIn && (AppComponent.isAdmin() || AppComponent.isPremiumUser() || AppComponent.isProUser() || this.purchased);
        } catch (err) {
          return false
        }
      } else {
        return true;
      }
    } catch (err) {
      return false;
    }

  }

  /*
  if(!isSignedIn) {
        this.isEligibleToView = false;
      } else {
        if(AppComponent.isAdmin() || AppComponent.isPremiumUser() || AppComponent.isProUser()) {
          this.isEligibleToView = true;
        } else {
          let firestore = new FirebaseTSFirestore;
          firestore.getDocument(
            {
              from: [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id, DBNames.USERS_PURCHASED_COLLECTION, this.course.id],
              onComplete: result => {
                if(result.exists) {
                  this.isEligibleToView = true;
                } else {
                  this.isEligibleToView = false;
                  
                }
              }
            }
          );
        }
      }
  */
  getCost(cost: number): string {
    try {
      if (cost > 0) {
        return `${cost / 100}`;
      } else {
        return "Free"
      }
    } catch (err) {
      return "Free"
    }
  }

  onTutorialDelete(tutorial: Tutorial) {
    this.selectedTutorialToDelete = tutorial;
    this.showDeleteMessage = true;
  }

  onConfirmDelete = () => {
    if (this.deletingTutorial) return;
    this.deletingTutorial = true;
    let selectedTutorialIndex = this.tutorials.indexOf(this.selectedTutorialToDelete);
    this.tutorials.splice(selectedTutorialIndex, 1);
    let firestore = new FirebaseTSFirestore();
    firestore.update(
      {
        path: [DBNames.C_COURSES, this.course.id, DBNames.C_COURSES_TUTORIAL_LIST, DBNames.D_COURSES_TUTORIAL_LIST_DOCUMENT],
        data: <Tutorial>{
          tutorials: this.tutorials
        },
        onComplete: (docRef) => {
          this.showDeleteMessage = false;
          this.deletingTutorial = false;
          this.selectedTutorialToDelete = null;
        }
      }
    );
  }
  onCancelDelete = () => {
    if (this.deletingTutorial) return;
    this.showDeleteMessage = false;
  }



  onSwapClick(tutorial: Tutorial) {
    if (this.swapModeOn && !this.swappingTutorial) {
      let selectedTutorialToSwapIndex = this.tutorials.indexOf(this.selectedTutorialToSwap);
      let swapWithTutorialIndex = this.tutorials.indexOf(tutorial);
      this.tutorials[swapWithTutorialIndex] = this.selectedTutorialToSwap;
      this.tutorials[selectedTutorialToSwapIndex] = tutorial;
      let firestore = new FirebaseTSFirestore();
      this.swappingTutorial = true;
      firestore.update(
        {
          path: [DBNames.C_COURSES, this.course.id],
          data: <Course>{
            tutorials: this.tutorials
          },
          onComplete: (docRef) => {
            this.selectedTutorialToSwap = null;
            this.swapModeOn = false;
            this.swappingTutorial = false;
          }
        }
      );

    } else {
      this.selectedTutorialToSwap = tutorial;
      this.swapModeOn = true;
    }
  }

  isAdmin() {
    return AppComponent.isAdmin();
  }
}

export interface ICourseTutorialMetadata {
  title: string,
  creatorId: string,
  id: string,
  tags: string[],
  cost: number
}