import { Component, OnInit } from '@angular/core';
import { FirebaseTSStorage } from 'firebasets/firebasetsStorage/firebaseTSStorage';
import { FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { ActivatedRoute } from '@angular/router';
import { DBNames } from 'src/app/classes/DBNames';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-course-files-download',
  templateUrl: './course-files-download.component.html',
  styleUrls: ['./course-files-download.component.css']
})
export class CourseFilesDownloadComponent implements OnInit {
  courseFileDoc: CourseFile;
  firestore: FirebaseTSFirestore;
  fileId: string;
  constructor(activatedRoute: ActivatedRoute) {
    this.firestore = new FirebaseTSFirestore();
    
    activatedRoute.params.subscribe(
      param => {

        this.fileId = param.fileId;

        this.firestore.getDocument(
          {
            path: [DBNames.COURSE_FILES_COLLECTION,  this.fileId],
            onComplete: (result) => {
              this.courseFileDoc = <CourseFile>result.data();
            }
          }
        );
      }
    ); 

  }

  ngOnInit(): void {
  }
 
  onDownloadFileClick(){
    if (this.courseFileDoc) {
      let storage = new FirebaseTSStorage();
      storage.downloadToLocalStorage(this.courseFileDoc.storageLocation);
      this.firestore.update(
        {
          path: [DBNames.COURSE_FILES_COLLECTION,  this.fileId, "data", "downloads"],
          data: {
            downloads: this.firestore.increment(1) 
          } 
        }
      );
    }

  
  }
  signedIn() {
    return AppComponent.isSignedIn;
  }
}

export class CourseFile {
  storageLocation: string;
  title: string;
}
