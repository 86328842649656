import { Component, OnInit } from '@angular/core';
import { render } from 'creditcardpayments/creditCardPayments';
import { FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { AppComponent } from 'src/app/app.component';
import { DBNames } from 'src/app/classes/DBNames';
import { FirebaseTSApp } from 'firebasets/firebasetsApp/firebaseTSApp';
import { IFirebaseUser, FirebaseUser } from 'src/app/classes/FirebaseUser';
import { SignInComponent } from 'src/app/2_x_x/tools/sign-in/sign-in.component';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  readonly YEARLY_PLAN = "Yearly";
  readonly QUARTERLY_PLAN = "Quarterly";
  selectedPlan: string;
  selectedPlanDuration: string;
  selectedPlanPrice: string;
  showPayment = false;
  paymentRendered = false;
  firestore: FirebaseTSFirestore;

  constructor() { }

  ngOnInit() {
    this.firestore = new FirebaseTSFirestore();
  }

  ngAfterContentInit(){
    
  }
  onQuarterlyPlanClick(){
    if(AppComponent.isSignedIn) {
      if(AppComponent.isAdmin() || AppComponent.isPremiumUser() || AppComponent.isProUser()) {
        alert("You already purchased a plan.");
      } else {
        this.showPayment = true;
        this.selectedPlan = this.QUARTERLY_PLAN;
        this.selectedPlanDuration = "/3 Months";
        this.selectedPlanPrice = "25";
      }
      
    } else {
      SignInComponent.onSignInTextClick();
    }
    
  }
  onYearPlanClick(){
    if(AppComponent.isSignedIn) {
      if(AppComponent.isAdmin() || AppComponent.isPremiumUser() || AppComponent.isProUser()) {
        alert("You already purchased a plan.");
      } else {
        this.showPayment = true;
        this.selectedPlan = this.YEARLY_PLAN;
        this.selectedPlanDuration = "/Year";
        this.selectedPlanPrice = "70";
      }
    } else {
      SignInComponent.onSignInTextClick();
    }
  }

  onClosePaymentClick(){
    this.showPayment = false;
    this.paymentRendered = false;
  }

  renderPayment(){
    if(this.paymentRendered) return;
    this.paymentRendered = true;
    render(
      {
        id: "#paypal-button-container",
        currency: "USD",
        value: `${this.selectedPlanPrice}.00`,
        onApprove: (details) => {
          let accountType = environment.FREE_USER;
          if(this.selectedPlan == this.QUARTERLY_PLAN) {
            accountType = environment.PRO_USER;
          } else if(this.selectedPlan == this.YEARLY_PLAN) {
            accountType = environment.PREMIUM_USER;
          }
          this.firestore.update(
            {
              path: [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id],
              data: <IFirebaseUser>{
                accountType,
                timestamp: FirebaseTSApp.getFirestoreTimestamp()
              },
              onComplete: () => {
                alert("Purchase Complete! You are not a " + accountType);
                this.onClosePaymentClick();
              }
            }
          );
        }
      }
    );
   
  }

}
