<div id="copyright">
  <p><span>&copy;2019 - {{currentYear}} Codeible. </span><span>All rights reserved.</span></p>
  <p (click)="onPolicyClick()">Policy</p>
  <div>
    <div class="g-ytsubscribe" data-channelid="UCML0bPHPGelL5KxpDJ7wBrw" data-layout="full" data-count="default"></div>
    <div>
      <a href="https://twitter.com/KingWaiMark" class="twitter-follow-button" data-show-count="false">Follow @codeible</a>
    </div>
    <div id="social-media">
      <a href="https://www.pinterest.com/codeible" target="_blank"><img class="social-media-icon" src="assets\images\icons\Pinterest.jpg" alt="Pinterest Logo"></a>
      <a href="https://www.patreon.com/codeible" target="_blank"><img class="social-media-icon" src="assets\images\icons\Patreon.jpg" alt="Patreon Logo"></a>
      <a href="https://www.reddit.com/user/Codeible" target="_blank"><img class="social-media-icon" src="assets\images\icons\Reddit.jpg" alt="Reddit Logo"></a>
      <a href="https://www.instagram.com/codeibleig" target="_blank"><img class="social-media-icon" src="assets\images\icons\Instagram.jpg" alt="Instagram Logo"></a>
    </div>
  </div>
</div>