<div class="k-center-box confirm-dialog">
  <div class="k-center-box-content">
    <div class="confirm-dialog-message-content">
      <h2>{{message}}</h2>
      <h2>{{subMessage}}</h2>
      <p>{{details}}<p>
      <hr>
      <div style="text-align: right;">
        <button (click)="onConfirmClick()"><i class="material-icons">check</i></button>
        <button (click)="onCancelClick()"><i class="material-icons">cancel</i></button>
      </div>
    </div>
  </div>
</div>