import { Component, Input, OnInit } from '@angular/core';
import { FirebaseTSFirestore, Limit, OrderBy, Where } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { DBNames } from 'src/app/classes/DBNames';
import { OverallQuizHighScore, QuizHighScore } from 'src/app/classes/QuizItems';
@Component({
  selector: 'app-quiz-high-score-view',
  templateUrl: './quiz-high-score-view.component.html',
  styleUrls: ['./quiz-high-score-view.component.css']
})
export class QuizHighScoreViewComponent implements OnInit {
  @Input() state?: QuizHighScoreViewState;
  @Input() quizId?: string;
  firestore = new FirebaseTSFirestore();
  highScoreArray: any [];
  retrievingData = false;
  showHighScoreView = true;
  
  constructor() { }

  ngOnInit(): void {

  }


  getOverallHighScore(){
    if(this.highScoreArray || this.retrievingData) return;
    this.retrievingData = true;

    this.firestore.getCollection(
      {
        path: [DBNames.C_VALUES, DBNames.VALUE_QUIZ_TOPIC, DBNames.C_OVERALL_QUIZ_HIGH_SCORES],
        where: [
          new OrderBy("score", "desc"),
          new OrderBy("timestamp", "desc"),
          new Limit(10)
        ],
        onComplete: (result) => {
          this.highScoreArray = new Array<OverallQuizHighScore>();
          result.docs.forEach( 
            doc => {
              let overallHighScore = <OverallQuizHighScore>doc.data();;
              this.highScoreArray.push(overallHighScore); 
            }
          );

        },
        onFail: (err) => {
          this.retrievingData = false;
        }
      }
    );
  }
  getQuizHighScore(){

    if(this.quizId == null || this.highScoreArray || this.retrievingData) return;
    this.retrievingData = true;
    this.firestore.getCollection(
      {
        path: [DBNames.C_VALUES, DBNames.VALUE_QUIZ_TOPIC, DBNames.C_QUIZ_HIGH_SCORES],
        where: [
          new Where("quizId", "==", this.quizId),
          new OrderBy("score", "desc"), 
          new OrderBy("timestamp", "desc"),
          new Limit(10)
        ],
        onComplete: (result) => {
          this.highScoreArray = new Array<QuizHighScore>(); 
          result.docs.forEach( 
            doc => {
              let highScores = <QuizHighScore>doc.data();
              this.highScoreArray.push(highScores); 
            }
          );

        },
        onFail: (err) => {
          this.retrievingData = false;

        }
      }
    );
  }

  onQuizHighScoreViewClick(){

    switch(this.state? this.state: QuizHighScoreViewState.OVERALL){
      case QuizHighScoreViewState.OVERALL:
        this.getOverallHighScore();
        break;
      case QuizHighScoreViewState.QUIZ:
        this.getQuizHighScore();
        break;
    } 
  }

  getTitle(){
    switch(this.state){
      case QuizHighScoreViewState.OVERALL:
        return "High Scores";
      case QuizHighScoreViewState.QUIZ:
        return `${this.quizId} High Scores`;
      default: 
        return ""; 
    }
  }

  onHideHighScoreViewClick(){
    this.showHighScoreView = this.showHighScoreView? false: true; 
  }

  getHideIconText(){
    if(this.showHighScoreView) return "keyboard_arrow_right";
    return "keyboard_arrow_left";
  }

}
export enum QuizHighScoreViewState {
  OVERALL,
  QUIZ
}
