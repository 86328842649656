import { Component, OnInit, Input } from '@angular/core';
//import { TutorialContent } from 'src/app/classes/TutorialContent';


@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit {
  hasImage = false;
  //@Input() content: TutorialContent [];
  public static readonly TYPE = "img";
  readonly original = "original";
  readonly fullWidth = "fullWidth";
  style: string = this.original;
  constructor() { }

  ngOnInit() {
  }

  onImageChange(imageInput: HTMLInputElement, imageDisplay: HTMLImageElement) {
    const fileReader = new FileReader();
    
    fileReader.onload = (onLoad) => {
    
      try{
        imageDisplay.src = fileReader.result.toString();
        this.hasImage = true;
      } catch (err){}
    }
    fileReader.readAsDataURL(imageInput.files[0]);
  }
  onRadioItemClick(radioButton: HTMLInputElement, imageDisplay: HTMLImageElement){
    switch(radioButton.value) {
      case this.original: imageDisplay.style.width = "auto"; this.style = this.original; break;
      case  this.fullWidth: imageDisplay.style.width = "100%"; this.style = this.fullWidth; break;
    }
  }
  onConfirmClick(imageInput: HTMLInputElement, image: HTMLImageElement) {
   /* const tutorialContent = new TutorialContent(
      {
        type: ImageComponent.TYPE,
        style: `style:${this.style}`,
        content: image.src,
        file: imageInput.files[0]
      }
    );
    this.content.push(tutorialContent);
    imageInput.value = "";
    image.src = "";*/
  }

  public static setStylesOf(element: HTMLElement, extractedStyles: string []){
    for(let style of extractedStyles){
      switch(style) {
        case "original": element.style.width = "auto"; break;
        case "fullWidth": element.style.width = "100%"; break;
      }
    }
    
  }
}
