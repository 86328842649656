
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FirebaseTSApp } from 'firebasets/firebasetsApp/firebaseTSApp';
import { BatchOperation, FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { AppComponent } from 'src/app/app.component';
import { DBNames } from 'src/app/classes/DBNames';
import { OverallQuizHighScore, QuizHighScore, QuizSubmitData, UserQuizHistory } from 'src/app/classes/QuizItems';


@Component({
  selector: 'app-quiz-submit-dialog',
  templateUrl: './quiz-submit-dialog.component.html',
  styleUrls: ['./quiz-submit-dialog.component.css'],

})
export class QuizSubmitDialogComponent implements OnInit {
  dataInterval: any;
  score: number = 0;
  firestore = new FirebaseTSFirestore();
  finishedUpdaingScore = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: QuizSubmitData,
  private dialogRef: MatDialogRef<QuizSubmitDialogComponent>,
  private router: Router) { 

    this.dataInterval = setInterval(
      () => {
        let incrementValue = (data.score / 100);
        this.score += incrementValue < 0? 1: incrementValue;
        if (this.score >= data.score) {
          this.score = data.score;
          clearInterval(this.dataInterval);
        }
      },
      10
    );

    this.getUserQuizHistory(data.quizId);
 

  }

  ngOnInit(): void { 
    window.scrollTo(0, 0);
  }

  ngOnDestroy(){
    clearInterval(this.dataInterval);
  }

  getScore(){
    return Math.floor(this.score);
  }

  getMaxScore(){ 
    return this.data.maxScore;
  }

  getUserQuizHistory(quizId: string){

    let quizPath = [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id, DBNames.USER_QUIZ_HISTORY_COLLECTION, quizId];
    this.firestore.getDocument(
      {
        path: quizPath,
        onComplete: (result) => { 
          let quizHistory = <UserQuizHistory>result.data(); 
          this.getUserQuizHighScore(quizPath, quizHistory);
        }
      }
    );
  
  }
  getUserQuizHighScore(quizHistoryPath: string[], quizHistory: UserQuizHistory){
    let firebaseUser = AppComponent.firebaseUser;
    let quizHighScorePath = [DBNames.C_VALUES, DBNames.VALUE_QUIZ_TOPIC, DBNames.C_QUIZ_HIGH_SCORES, `${firebaseUser.id}-${this.data.quizId}`];
    this.firestore.getDocument(
      {
        path: quizHighScorePath,
        onComplete: (result) => {
          this.getUserOverallHighScore(quizHistoryPath, quizHighScorePath, quizHistory, result.exists); 
        }
      }
    );
  }

  getUserOverallHighScore(quizHistoryPath: string[], quizHighScorePath: string[], quizHistory: UserQuizHistory, quizHighScoreExists: boolean){
    let firebaseUser = AppComponent.firebaseUser;
    let overallQuizHighScorePath = [DBNames.C_VALUES, DBNames.VALUE_QUIZ_TOPIC, DBNames.C_OVERALL_QUIZ_HIGH_SCORES, firebaseUser.id];
    this.firestore.getDocument(
      {
        path: overallQuizHighScorePath,
        onComplete: (result) => {
          let isHighScore = this.data.score >= quizHistory.score;
          var oper = [];

          oper.push(this.updateUserHistory(quizHistoryPath, quizHistory, isHighScore));
          if(isHighScore) {
            oper.push(this.determineBatchOperationForHighScore(quizHighScorePath, quizHighScoreExists));
            oper.push(this.determineBatchOperationForOverallHighScore(overallQuizHighScorePath, quizHistory, result.exists));
          }
          this.firestore.all(
            {
              operations: oper,
              onComplete:() => {
                this.finishedUpdaingScore = true; 
              }
            }
          );
        }
      }
    );
  }
  determineBatchOperationForHighScore(quizHighScorePath: string [], quizHighScoreExists: boolean): BatchOperation<QuizHighScore>{
    return quizHighScoreExists? 
      this.updateHighScoreForUser(quizHighScorePath): 
      this.createHighScoreForUser(quizHighScorePath);
  }
  updateHighScoreForUser(quizHighScorePath: string []): BatchOperation<QuizHighScore>{ 
    return new BatchOperation(
      "update",
      quizHighScorePath,
      <QuizHighScore>{ 
        score: this.data.score,
        timestamp: FirebaseTSApp.getFirestoreTimestamp()
      }
    );
  }

  createHighScoreForUser(quizHighScorePath: string []): BatchOperation<QuizHighScore>{
    return new BatchOperation(
      "create",
      quizHighScorePath,
      <QuizHighScore>{ 
        score: this.data.score,
        timestamp: FirebaseTSApp.getFirestoreTimestamp(),  
        name: AppComponent.firebaseUser.name,
        quizId: this.data.quizId,
        uid: AppComponent.firebaseUser.id
      }
    );
  }
  determineBatchOperationForOverallHighScore(overallQuizHighScorePath: string [], quizHistory: UserQuizHistory, overallHighScoreExists: boolean): BatchOperation<any>{
    return overallHighScoreExists?
      this.updateOverallHighScoreForUser(overallQuizHighScorePath, quizHistory):
      this.createOverallHighScoreForUser(overallQuizHighScorePath);
  }
  updateOverallHighScoreForUser(overallQuizHighScorePath: string [], quiz: UserQuizHistory): BatchOperation<any>{
    return new BatchOperation(
      "update",
      overallQuizHighScorePath,
      { 
        score: this.firestore.increment(this.data.score - quiz.score),
        timestamp: FirebaseTSApp.getFirestoreTimestamp() 
      }
    );
  }
  createOverallHighScoreForUser(overallQuizHighScorePath: string[]): BatchOperation<OverallQuizHighScore>{
    return new BatchOperation(
      "create",
      overallQuizHighScorePath,
      <OverallQuizHighScore>{ 
        score: this.data.score,
        timestamp: FirebaseTSApp.getFirestoreTimestamp(),  
        name: AppComponent.firebaseUser.name,
      }
    );
  }
  updateUserHistory(quizPath: string [], quiz: UserQuizHistory, isHighScore: boolean): BatchOperation<UserQuizHistory>{
    
    return new BatchOperation(
      "update",
      quizPath,
      <UserQuizHistory>{ 
        score: isHighScore? this.data.score: quiz.score,
        completed: true,
        highestScoreRecordedOn: isHighScore? FirebaseTSApp.getFirestoreTimestamp(): quiz.highestScoreRecordedOn,
        questionResults: this.data.questionResults
      }
    );
  }
  onCloseClick(){
    this.dialogRef.close();
  }

}
