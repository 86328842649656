// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBIhGWE7KSJ2SIH5QoqMxQytD-UBYudiNg",
    authDomain: "codeible.firebaseapp.com",
    databaseURL: "https://codeible.firebaseio.com",
    projectId: "codeible",
    storageBucket: "codeible.appspot.com",
    messagingSenderId: "685431973394",
    appId: "1:685431973394:web:eca8f681d5ee1467"
  },
  ADMIN: "ADMIN_9032490342903490348509340932",
  FREE_USER: "FREE USER",
  PRO_USER: "PRO USER",
  PREMIUM_USER: "PREMIUM USER"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
