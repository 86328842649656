
<div id="bookmarks-page" *ngIf="isSignedIn() else noPriv">
  <div style="background-color: var(--primary-color); position: relative;" *ngFor="let tutorial of tutorials">
    <app-tutorial-header (click)="onBookmarkItemClick(tutorial)"  [tutorial]="tutorial"></app-tutorial-header>
    <button (click)="onDeleteBookmarkClick(tutorial)" id="delete-bookmark-button">X</button>
  </div>
</div>
<div *ngIf="isSignedIn() && tutorials.length == 0" style="margin-top: 2em; text-align: center; padding: 2em;">
  <h1>You currently have no bookmarks.</h1>
</div>
<!--Does not have privileges-->
<ng-template #noPriv>
  <div style="margin-top: 7em; text-align: center; padding: 2em;">
    <h1>You must sign in to view this page</h1>
  </div>
</ng-template>

<div id="view-bookmark-platter" *ngIf="selectedTutorial && shouldShowTutorialContents">
  <div style="max-width: 60em; margin: auto; padding: 1em;">
    <app-tutorial-page [tutorial]="selectedTutorial" [contentArray]="selectedTutorial.content"></app-tutorial-page>
  </div>
</div>

<button id="close-tutorial-content-button" *ngIf="selectedTutorial && shouldShowTutorialContents" (click)="onCloseTutorialContentClick()">
  X
</button>