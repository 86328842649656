
<!--Uses the CSS of the edit tutorial page-->
<section id="new-tutorial">
  <div>
    <!--Creator tools
    <div id="creator-tools">
      <div id="creator-tools-container">
        <div class="creator-tool" *ngIf="isCreator()" (click)="onEditClick()">
          <span class="material-icons">edit</span>
          <span>Edit</span>
        </div>
        <div class="creator-tool" *ngIf="isLoggedIn()">
          <span class="material-icons">bookmark_border</span>
          <span>Bookmark</span>
        </div>
      </div>
    </div>-->
    <div id="new-tutorial-page">
      <app-tutorial-page [tutorial]="tutorial" [contentArray]="visibleContent"></app-tutorial-page>
    </div>
    <!--
      <button *ngIf="showMoreContentButton()" class="price-button" (click)="onShowFullContentClick()">Show full content.</button>
      <button *ngIf="showPayForContentButton()" class="price-button" (click)="onPayForContentClick()">Pay $ <span class="price">{{getCost()}}</span> to unlock full content.</button>      
      
    -->
  </div>

</section>
<!--BUY-->
<div *ngIf="showPurchaseScreen" id="view-tutorial-purchase-container">
  <div id="view-tutorial-purchase-container-content">
    <!--HEADER-->
    <app-tutorial-header [tutorial]="tutorial"></app-tutorial-header>
    <button *ngIf="showPayForContentButton()" class="price-button">Pay $ <span class="price">{{getCost()}}</span> to
      unlock full content.</button>
    <div id="tutorial-payment-close-button" (click)="onClosePaymentClick()">X</div>
    <hr>
    <div id="paypal-button-container"></div>
    {{renderPayments()}}
  </div>

</div>
<app-footer></app-footer>