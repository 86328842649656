import { Component, OnInit, Input } from '@angular/core';
//import { TutorialContent } from 'src/app/classes/TutorialContent';



@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.css']
})
export class TextboxComponent  implements OnInit {
  @Input() show: boolean;
  //@Input() content: TutorialContent [];
  //@Input() contentItem?: TutorialContent;
  @Input() editMode?: boolean;
  readonly startOffInfo = "txtbox";
  readonly left = "Left";
  readonly right = "Right";
  readonly center = "Center";
  readonly setAsTitle = "Set as Title";
  readonly focus = "focus";
  textAlignStyle: string = "style: left";
  titleStyle: string = "";
  focusStyle: string = "";
  constructor() { 
  }

  ngOnInit() {
  }

  onConfirmClick(textboxEditor: HTMLInputElement){
    /*if(this.editMode) {
      try{
        const tutorialContent = this.genTutorialContent(textboxEditor);
        this.contentItem.copyTutorialContent(tutorialContent);

        textboxEditor.setAttribute("value", "");
      } catch (err) {
        console.log(err);
      }
    } else {
      try{
        const tutorialContent = this.genTutorialContent(textboxEditor);
        this.content.push(tutorialContent);
        textboxEditor.setAttribute("value", "");
      } catch (err) {
        console.log(err);
      }
    }*/
  }
  /**
   * Generate the content string so that it is compatible with the ContentDisplay.
   * @param textboxEditor 
   
  genTutorialContent(textboxEditor: HTMLInputElement): TutorialContent{
    const tutorialContent = new TutorialContent(
      {
        type: this.startOffInfo,
        style: `${this.titleStyle == ""? this.titleStyle: "," + this.titleStyle}` +
               `, ${this.textAlignStyle}` +
               `${this.focusStyle == ""? this.focusStyle: "," + this.focusStyle}`,
        content: textboxEditor.getAttribute("value")
      }
    );

    return tutorialContent;
  }*/

  onOrientaitonClick(orientation: HTMLInputElement, kTextEditor: HTMLDivElement){
    switch(orientation.value){
      case this.left: kTextEditor.style.textAlign = "left"; this.textAlignStyle = "style:" + this.left; break;
      case this.right: kTextEditor.style.textAlign = "right"; this.textAlignStyle = "style:" + this.right; break;
      case this.center: kTextEditor.style.textAlign = "center"; this.textAlignStyle = "style:" + this.center; break;
      case this.setAsTitle: 
        this.setElementAsTitle(orientation, kTextEditor);
      break;
      case this.focus:
        this.setFocus(orientation, kTextEditor);
        break;
    }
  }
  setElementAsTitle(orientation: HTMLInputElement, kTextEditor: HTMLDivElement){
    if(orientation.checked) {
      kTextEditor.style.borderWidth = "3px";
      kTextEditor.style.borderStyle = "dashed";
      kTextEditor.style.borderBottomColor = "black";
      kTextEditor.style.borderTop = "transparent";
      kTextEditor.style.borderLeftColor = "transparent";
      kTextEditor.style.borderRightColor = "transparent";
      this.titleStyle = "style:" + this.setAsTitle;
    } else {
      kTextEditor.style.borderWidth = "unset";
      kTextEditor.style.borderStyle = "none";
      kTextEditor.style.borderBottomColor = "unset";
      kTextEditor.style.borderTop = "unset";
      kTextEditor.style.borderLeftColor = "unset";
      kTextEditor.style.borderRightColor = "unset";
      this.titleStyle = "";
    }
  }

  setFocus(orientation: HTMLInputElement, kTextEditor: HTMLDivElement){
    if(orientation.checked) {
      this.focusStyle = "style:" + this.focus;
    } else {
      this.focusStyle = "";
    }
  }

  public static setStylesOf(element: HTMLElement, extractedStyles: string []){
    for(let style of extractedStyles){
      switch(style.trim()) {
        case "Left": element.style.textAlign = "left"; break;
        case "Center": element.style.textAlign = "center"; break;
        case "Right": element.style.textAlign = "right"; break;
        case "Set as Title": 
          element.style.borderWidth = "3px";
          element.style.borderStyle = "dashed";
          element.style.borderColor = "transparent transparent black transparent";
        break;
        case "focus": 
          element.style.backgroundColor = "rgb(255, 255, 255)";
          element.style.padding = "10px 20px";
          element.style.boxShadow = "0px 0px 2px 2px rgba(0, 0, 0, 0.200)";
          element.style.borderRadius = "5px";
        break;
      }
    }

  }
}
