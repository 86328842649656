<div id="tutorial-header">
  <div id="tutorial-title" class="tutorial-header-item">{{getTitle()}}</div>
  <div *ngIf="getDescription()" id="tutorial-description" class="tutorial-header-item">{{getDescription()}}</div>
  <div *ngIf="getAuthors()" id="tutorial-authors" class="tutorial-header-item">By: <span id="tutorial-author">{{getAuthors()}}</span></div>
  <div id="tutorial-tags" class="tags-container">
   <div #tagsElement *ngFor="let tag of getTags()" class="topic">
     <img [src]="getTagIcon(tag.stringValue? tag.stringValue: tag)" style="width: 2em; border-radius: 50%;  margin-right: .2em;">
    </div>
  </div> 
  <!--<button (click)="onPreviewClick()">Preview</button>-->
</div>