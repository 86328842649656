<div style="position: relative;">





  <div class="tutorial-content-container">
    <div id="tutorial-page-tools">
      <button class="tool-item" *ngIf="isCreator() && isLoggedIn()" (click)="onEditClick()">
        <span class="material-icons">edit</span>
        <span>Edit</span>
      </button>
      <button *ngIf="isLoggedIn()" class="tool-item" (click)="onBookmarkClick()">
        <span class="material-icons">bookmark_border</span>
        <span>Bookmark</span>
      </button>
      <a [href]="getTwitterRedirectUrl()" target="_blank"><img src="assets/images/icons/twitter_blue.png" width="32" height="32" alt="Twitter Logo"></a>
      <a [href]="getFacebookRedirectUrl()" target="_blank"><img src="assets/images/icons/fb_blue.png" width="32" height="32" alt="Facebook Logo"></a>

    </div>
    <!--HEADER-->
    <app-tutorial-header [tutorial]="tutorial"></app-tutorial-header>
    <!--Content-->
    <div id="tutorial-content">
      <!--Tutorial contents-->
      <div *ngFor="let content of contentArray; index as i;">
        <div *ngIf="i % 15 == 0 && i != 0" style="overflow: hidden;">
          <app-google-ad 
            [adStyle]="'display:block; text-align:center;'" 
            [adSlot]="'6550858413'"
            [adFormat]="'fluid'"
            [adLayout]="'in-article'"
          ></app-google-ad>
        </div>
        <app-tutorial-content [content]="content"></app-tutorial-content>
      </div>
    </div>
  </div>

</div>
