import { Component, OnInit, Input } from '@angular/core';
import { ImageComponent } from '../../tools/image/image.component';

@Component({
  selector: 'app-image-display',
  templateUrl: './image-display.component.html',
  styleUrls: ['./image-display.component.css']
})
export class ImageDisplayComponent implements OnInit {
  @Input() src: string;
  @Input() extractedStyles: string [];
  gotStyles = false;
  constructor() { }

  ngOnInit() {
    
  }

  getStyles(imageElement: HTMLImageElement){
    if(!this.gotStyles){
      this.gotStyles = true;
      try{
        ImageComponent.setStylesOf(imageElement, this.extractedStyles);
      } catch (err) {}
    }
  }

}
