
<div *ngIf="courseFileDoc && signedIn()" id="course-file">
    
    <div style="text-align: center;">
        <h1>{{courseFileDoc?.title}}</h1>
        <div>
            <button mat-flat-button color="warn" (click)="onDownloadFileClick()">Download File</button>
        </div>
        <div style="margin-top: 1em;">
            <app-google-ad [adStyle]="'display:inline-block;width:300px;height:300px'" [adSlot]="'6998592733'"></app-google-ad>
        </div>
    </div>



</div>


<div *ngIf="!signedIn()" class="course-file-blocker">
    <div style="text-align: center;">
        <h1>Sign in to download files</h1>
        <app-google-ad [adStyle]="'display:inline-block;width:300px;height:300px'" [adSlot]="'6998592733'"></app-google-ad>
    </div>
</div>