<div id="projects">


    <div class="project-preview">

        <img src="https://firebasestorage.googleapis.com/v0/b/codeible.appspot.com/o/Projects%2FpGNosyqdtAeDQpuiH08G%2FPROJECT_COVER.jpg?alt=media&token=1378da67-2b2a-40a3-83df-b7ac170d11f2">
        <div>
            Building a Social Media Site with Angular and Firebase
        </div>
    </div>


</div>