import { Component, OnInit, Input } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { FirebaseTSStorage } from 'firebasets/firebasetsStorage/firebaseTSStorage';
import { DBNames } from 'src/app/classes/DBNames';
import { Course } from '../course-header/course-header.component';

@Component({
  selector: 'app-course-header-creator-tools',
  templateUrl: './course-header-creator-tools.component.html',
  styleUrls: ['./course-header-creator-tools.component.css']
})
export class CourseHeaderCreatorToolsComponent implements OnInit {
  @Input() course: Course;
  @Input() courses: Course [];
  constructor() { }

  ngOnInit() {
  }
  isCreator(course: Course){
    try{
      return AppComponent.firebaseUser.id == course.creatorId;
    } catch (err) {}
    return false;
  }
  getPublishedText(course: Course){
    try{
      return course.published? "Published" : "Unpublished";
    } catch (err) {}
    return "Unpublished";
  }
  onDeleteClick(course: Course){
    if(!course) return;
    let firestore = new FirebaseTSFirestore();
    let storage = new FirebaseTSStorage();
    firestore.delete(
      {
        path: [DBNames.C_COURSES, course.id],
        onComplete: () => {
          this.courses.splice(this.courses.indexOf(course), 1);
          storage.deleteWithUrl({
            url: course.imgUrl
          });
        }
      }
    );
  }

  onPublishClick(course: Course){
    let firestore = new FirebaseTSFirestore();
    const published = course.published? false: true;
    firestore.update(
      {
        path: [DBNames.C_COURSES, course.id],
        data: {
          published
        },
        onComplete: (docRef) => {
          course.published = published
        }
      }
    );
  }
}
