<div style="height: 100%;" class="k-center-box" >
  <div  class="k-center-box-content">
    <div id="security">
      <h1>{{title}}</h1>
      <p>{{desc}}</p>
      <h3>{{linksToOtherSites}}</h3>
      <p>{{linksToOtherSitesDesc}}</p>
      <h3>{{logData}}</h3>
      <p>{{logDataDesc}}</p>
    </div>
  </div>
</div>