import { Component, OnInit, Input } from '@angular/core';
import { TutorialHeaderFunctions } from 'src/app/classes/TutorialHeaderFunctions';
import { Tutorial } from 'src/app/classes/TutorialItem';


@Component({
  selector: 'app-tutorial-header',
  templateUrl: './tutorial-header.component.html',
  styleUrls: ['./tutorial-header.component.css']
})
export class TutorialHeaderComponent extends TutorialHeaderFunctions implements OnInit {
  @Input() tutorial: Tutorial | any;
  constructor() { 
    super();
  }

  ngOnInit() {
    
  }

  getTitle(){
    if(!this.tutorial) return "";
    return this.tutorial.title.stringValue? this.tutorial.title.stringValue: this.tutorial.title;
  }

  getDescription(){
    try {
      return this.tutorial.description.stringValue? this.tutorial.description.stringValue: this.tutorial.description;
    } catch (err) {
      return null;
    }
    
  }

  getAuthors(){
    try {
      return this.tutorial.authors.stringValue?  this.tutorial.authors.stringValue: this.tutorial.authors;
    } catch (err) {
      return null;
    }
    
  }

  getTags(){
    
    if(!this.tutorial) return [];
    if(this.tutorial && this.tutorial.tags.arrayValue) {
      return this.tutorial?.tags.arrayValue.values; 
    } else {
      return this.tutorial.tags
    }

  }

  changeColor(text: string, element: HTMLElement) {
    let r = 0;
    let g = 0;
    let b = 0;
    for(let i = 0; i < text.length; i++) {
      const index = i % 3;
      if(index == 0) {
        r += text.charCodeAt(i);
      } else if(index == 1){
        g += text.charCodeAt(i);
      } else if(index == 2) {
        b += text.charCodeAt(i) ;
      }
    }
    const backgroundR = r % 255;
    const backgroundG = g % 255;
    const backgroundB = b % 255;
    //element.style.backgroundColor = `rgb(${backgroundR}, ${backgroundG}, ${backgroundB})`;
    const textR = 255 - backgroundR;
    const textG = 255 - backgroundG;
    const textB = 255 - backgroundB;
    //element.style.color = `rgb(${textR}, ${textG}, ${textB})`;
    element.style.color = "beige";
    return text;
  }
}
