import { Component, OnInit } from '@angular/core';
import { MetatagService } from 'src/app/services/metatag.service';


@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {
  mobileSize = false;
  constructor(private meta: MetatagService) { 
    meta.setMetaTags(
      {
        title: "Policy", 
        description: "We may update our Policies from time to time. Thus, we advise you to review this page periodically for any changes.",
        image: "https://codeible.com/assets/images/icons/codible_256.png"
      }
    );
  }

  ngOnInit() {


  }

}
