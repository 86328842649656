import { Component, OnInit, Input } from '@angular/core';

import { DBNames } from 'src/app/classes/DBNames';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';

import { FirebaseTSFirestore, Where } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';

import { Course } from '../course-header/course-header.component';
import { Tutorial } from 'src/app/classes/TutorialItem';


@Component({
  selector: 'app-add-to-course',
  templateUrl: './add-to-course.component.html',
  styleUrls: ['./add-to-course.component.css']
})
export class AddToCourseComponent implements OnInit {
  private firestore: FirebaseTSFirestore;
  show = false;
  courses: Course [];
  @Input() tutorial: Tutorial;

  constructor(router: Router) { 
    this.firestore = new FirebaseTSFirestore();
    this.courses = new Array<Course>();
  }

  ngOnInit() {
  }

  getCourses(){
    if(!this.tutorial) return;
    this.show = this.show? false: true;
    if(this.courses.length > 0) return;
    this.firestore.getCollection(
      {
        path: [DBNames.C_COURSES],
        where: [
          new Where("creatorId", "==", AppComponent.firebaseUser.id)
        ],
        onComplete: (result) => {
          result.docs.forEach(
            e => {
              let course = <Course>e.data();
              course.id = e.id;
              this.courses.push(course);
            }
          );
        }
      }
    );
  }

  onCourseClick(course: Course){
    if(!this.tutorial) return;
    this.show = false;
    let tutorialListDocumentFromCoursesCollection = [DBNames.C_COURSES, course.id, DBNames.C_COURSES_TUTORIAL_LIST, DBNames.D_COURSES_TUTORIAL_LIST_DOCUMENT];
    this.firestore.getDocument(
      {
        path: tutorialListDocumentFromCoursesCollection,
        onComplete: (result) => { 
          if(result.exists){
            /*
            let databaseArray = <Array<Tutorial>>result.data().tutorials;
            if(databaseArray) {
              tutorialArray = databaseArray;  
            }
            tutorialArray.push(this.tutorialMetadataFiter(this.tutorial));*/
            let tutorialArray = new Array<any>();
            let databaseArray = <Array<Tutorial>>result.data().tutorials;
            if(databaseArray) {
              tutorialArray = databaseArray;  
            }
            tutorialArray.push(this.tutorialMetadataFiter(this.tutorial));
            this.firestore.update(
              {
                path: tutorialListDocumentFromCoursesCollection,
                data: {
                  tutorials: tutorialArray 
                },
                onComplete: docRef => {
                  alert("Added to " + course.title);
                  this.show = false;
                }
              }
            );
          } else {
            this.firestore.create(
              {
                path: tutorialListDocumentFromCoursesCollection,
                data: {
                  tutorials: [this.tutorialMetadataFiter(this.tutorial)]
                },
                onComplete: docRef => {
                  alert("Added to " + course.title);
                  this.show = false;
                }
              }
            );
          }
        }
      }
    );
  }

  tutorialMetadataFiter(tutorial: Tutorial): Tutorial {
    return <Tutorial>{
      title: tutorial.title,
      creatorId: tutorial.creatorId,
      id: tutorial.id,
      tags: tutorial.tags,
      cost: tutorial.cost
    }
  }


  createGroup(titleInput: HTMLInputElement){
    
    const title = titleInput.value;
    if(title.length <= 0) return;

    this.firestore.create(
      {
        path: [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id, DBNames.USERS_COURSES_COLLECTION],
        data: <Course>{
          title
        },
        onComplete: (docId) => {
          
          //this.courses.push(new Course({title}, docId));
          titleInput.value = "";
        }
      }
    );
  }

}

