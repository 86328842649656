import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { ActivatedRoute, Router } from '@angular/router';

import { DBNames } from 'src/app/classes/DBNames';
import { AppComponent } from 'src/app/app.component';
import { SignInComponent } from 'src/app/2_x_x/tools/sign-in/sign-in.component';
import { render } from 'creditcardpayments/creditCardPayments';
import { IPurchasedItem } from 'src/app/classes/TutorialContent';
import { FirebaseTSApp } from 'firebasets/firebasetsApp/firebaseTSApp';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { Tutorial, TutorialContent } from 'src/app/classes/TutorialItem';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-view-tutorial',
  templateUrl: './view-tutorial.component.html',
  styleUrls: ['./view-tutorial.component.css']
})
export class ViewTutorialComponent implements OnInit {

  firestore: FirebaseTSFirestore;
  tutorial: Tutorial;
  tutorialContent: TutorialContent;
  visibleContent: string [] = [];
  
  showPurchaseScreen: boolean = false;
  renderedPayments = false;
  userSignedInSubscription: Subscription;
  readonly PURCHASES_LISTENER = "VIEW_TUTORIAL_USER_PURCHASES"; 
  purchased: boolean;
  
  constructor(activatedRoute: ActivatedRoute, private router: Router, @Inject(PLATFORM_ID) private platformId: Object) { 
    this.firestore = new FirebaseTSFirestore();
    activatedRoute.params.subscribe(
      params => {
        let tutorialId = params.id;
        if(tutorialId) {
          this.getTutorial(tutorialId);
          //this.listenToUserPurchases(tutorialId);
        }
      }
    );
  }

  ngOnInit() {
    if(isPlatformBrowser(this.platformId)){
      window.scroll(0, 0);
    }
  }
  ngOnDestroy(){
    //this.removeMetaTagsForTutorial();
    this.firestore.stopListeningTo(this.PURCHASES_LISTENER);

  }
  listenToUserPurchases(tutorialId: string){
    this.firestore.stopListeningTo(this.PURCHASES_LISTENER);
    this.userSignedInSubscription = AppComponent.isSignedInSubscription.subscribe(
      isSignedIn => {
        if(isSignedIn) {
          this.firestore.listenToDocument(
            {
              name: this.PURCHASES_LISTENER,
              path: [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id, DBNames.USERS_PURCHASED_COLLECTION, tutorialId],
              onUpdate: (result) => {
                this.purchased = result.exists;
              }
            }
          );
        } else {
          this.firestore.stopListeningTo(this.PURCHASES_LISTENER);
        }
      }
    );
  }
  getTutorial(tutorialId: string){
    let firestore = new FirebaseTSFirestore();
    firestore.getDocument(
      {
        path: [DBNames.C_TUTORIALS, tutorialId],
        onComplete: (result) => {
          this.tutorial = <Tutorial>result.data();
          this.tutorial.id = result.id;
          firestore.getDocument(
            {
              path: [DBNames.C_TUTORIALS, tutorialId, DBNames.C_TUTORIAL_HEADER_TUTORIAL_CONTENT, DBNames.D_TUTORIAL_HEADER_TUTORIAL_CONTENT_DOCUMENT],
              onComplete: (result) => {
                this.tutorialContent = <TutorialContent>result.data();
                this.populateVisibleContent(this.tutorial);
                //this.addMetaTagsForTutorial(this.tutorial);
              }
            }
          );

        }
      }
    );
  }




  getCost(): string {
    try{
      if(this.tutorial.cost > 0) {
        return `${this.tutorial.cost/100}`;
      } else {
        return "Free"
      }
    } catch (err) {
      return "Free"
    }
  }

  onShowFullContentClick(){
    this.populateVisibleContent(this.tutorial, true);
  }
  onPayForContentClick(){
    if(!AppComponent.isSignedIn) {
      SignInComponent.onSignInTextClick();
    } else {
      this.showPurchaseScreen = true;
    }
  }
  populateVisibleContent(tutorial: Tutorial, bypass?: boolean){
    /*
    if(this.hasCost(tutorial) && !bypass) {
      this.visibleContent = [];
      for(let i = 0; i < 5; i++) {
        this.visibleContent.push(this.tutorialContent.content[i]);
      }
    } else {
      this.visibleContent = this.tutorialContent.content;
    }
    */
    this.visibleContent = this.tutorialContent.content;
  }
  hasCost(tutorial: Tutorial){
    try{
      return tutorial.cost && tutorial.cost > 0;
    } catch (err) { return false;} 
  }


  isCreator(){
    try{
      return AppComponent.isSignedIn && AppComponent.firebaseUser.isAdmin() && (this.tutorial.id? AppComponent.firebaseUser.id == this.tutorial.creatorId: true);
    } catch (err) {

    }
    return false;
  }

  onEditClick(){
    if(this.isCreator()) {
      this.router.navigate(['create/tutorial/' + this.tutorial.id]);
    }
  }
  showPayForContentButton(){
    let authorized = this.isProUser() || this.isCreator() || this.isPremiumUser() || this.purchased;
    if(authorized) {
      return false;
    } else if(this.hasCost(this.tutorial) && !this.hasFullContent()){
      return true;
    }
    return false;
  }

  showMoreContentButton(){
    if(this.isCreator() || this.isProUser() || this.isPremiumUser() || this.purchased) {
      if(!this.hasFullContent()) {
        return true;
      }
    }
    return false;
  }
  hasFullContent(){
    try{
      return this.visibleContent.length == this.tutorial.content.length;
    } catch (err) {}
    return false;
  }
  isAdmin(){
    return AppComponent.isAdmin();
  }

  isPremiumUser(){
    try{
      return AppComponent.firebaseUser.accountType == environment.PREMIUM_USER;
    } catch (err) {
      return false;
    }
  }
  isProUser(){
    try{
      return AppComponent.firebaseUser.accountType == environment.PRO_USER;
    } catch (err) {
      return false;
    }
  }


  isLoggedIn(){
    return AppComponent.isSignedIn;
  }

  renderPayments() {
    if(this.renderedPayments) return;
    try{
      if(!this.tutorial) return;
      render(
        {
          id: "#paypal-button-container",
          value: `${this.tutorial.cost? this.tutorial.cost/100: 0}`,
          currency: "USD",
          onApprove: (details) => {
            this.firestore.create(
              {
                path: [DBNames.USERS_COLLECTION, AppComponent.firebaseUser.id, DBNames.USERS_PURCHASED_COLLECTION, this.tutorial.id],
                data: <IPurchasedItem> {
                  timestamp: FirebaseTSApp.getFirestoreTimestamp()
                },
                onComplete: (docId) => {
                  this.onClosePaymentClick();
                  alert("Purchase complete!");
                }
              }
            );
          }
        }
      );
      this.renderedPayments = true;
    } catch (err) {

    }
  }

  onClosePaymentClick(){
    this.showPurchaseScreen = false;
    this.renderedPayments = false;
  }
}
