import { Component, OnInit } from '@angular/core';
import { FirebaseTSFirestore, OrderBy, Limit, Where } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { DBNames } from 'src/app/classes/DBNames';
import { Router, ActivatedRoute } from '@angular/router';
import { Tutorial } from 'src/app/classes/TutorialItem';


@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css']
})
export class ExploreComponent implements OnInit {
  tutorials: Tutorial [];
  searchCriteria = null;
  constructor(private router: Router, private route: ActivatedRoute) { 
    
  }

  ngOnInit() {

   this.route.params.subscribe(
     params => {
      if(params.search) {

        this.getTutorials((<string>params.search).replace(",", " "));
      } else {
        this.getTutorials_noFilter();
        window.scroll(0, 0);
      }
     }
   );
  }

  getTutorials_noFilter(){
    let firestore = new FirebaseTSFirestore();
    this.tutorials = new Array<Tutorial>();
    firestore.getCollection(
      {
        path: [DBNames.C_TUTORIALS],
        where: [new OrderBy("dateCreated", "desc"), new Limit(20)],
        onComplete: (result) => {
          result.docs.forEach(
            e => {
              let tutorial = <Tutorial>e.data();
              tutorial.id = e.id;
              this.tutorials.push(tutorial);
            }
          );
          
        }
      }
    );
  }

  getTutorials(searchValue: string){
    if(!(searchValue.length > 0)) {
      this.searchCriteria = null;
      this.getTutorials_noFilter();
      this.router.navigate(['explore']);
      return;
    }
    let firestore = new FirebaseTSFirestore();
    this.tutorials = new Array<Tutorial>();
    this.searchCriteria = searchValue.split(" ");
    this.router.navigate(['explore', {search: this.searchCriteria }]);
    for(let i = 0; i < this.searchCriteria.length; i++) {
      let str = this.searchCriteria[i];
      this.searchCriteria[i] = str.trim().charAt(0).toUpperCase() + str.substring(1, str.length).toLowerCase();
    }
    if(this.searchCriteria.length > 10) {
      this.searchCriteria.splice(10, this.searchCriteria.length);
    }
    firestore.getCollection(
      {
        path: [DBNames.C_TUTORIALS],
        where: [new Where("tags", "array-contains-any", this.searchCriteria), new OrderBy("dateCreated", "desc"), new Limit(20)],
        onComplete: (result) => {
          result.docs.forEach(
            e => {
              let tutorial = <Tutorial>e.data();
              tutorial.id = e.id;
              this.tutorials.push(tutorial);
            }
          );
          
        }
      }
    );
  }
  onTutorialClick(tutorial: Tutorial){
    this.router.navigate([`view/tutorial/${tutorial.id}`, {title: tutorial.title}]);
  }

  getCost(cost: number): string {
    try{
      let costToString = cost.toString();
      if(cost > 0) {
        return `${cost/100}`;
      } else {
        return "Free"
      }
    } catch (err) {
      return "Free"
    }
  }

  changeColor(text: string, element: HTMLElement) {
    let r = 0;
    let g = 0;
    let b = 0;
    for(let i = 0; i < text.length; i++) {
      const index = i % 3;
      if(index == 0) {
        r += text.charCodeAt(i);
      } else if(index == 1){
        g += text.charCodeAt(i);
      } else if(index == 2) {
        b += text.charCodeAt(i) ;
      }
    }
    const backgroundR = r % 255;
    const backgroundG = g % 255;
    const backgroundB = b % 255;
   // element.style.backgroundColor = `rgb(${backgroundR}, ${backgroundG}, ${backgroundB})`;
    const textR = 255 - backgroundR;
    const textG = 255 - backgroundG;
    const textB = 255 - backgroundB;
    //element.style.color = `rgb(${textR}, ${textG}, ${textB})`;
    element.style.color = "beige";
    return text;
  }

  onLoadMoreButtonClick(){
    try{
      let firestore = new FirebaseTSFirestore();
      let endOftutorialList = this.tutorials[this.tutorials.length - 1];
      let whereFilter = [new Where("dateCreated", "<=", endOftutorialList.dateCreated), new OrderBy("dateCreated", "desc"), new Limit(20)];
      if(this.searchCriteria) {
        whereFilter.push(new Where("tags", "array-contains-any", this.searchCriteria));
      }
      firestore.getCollection(
        {
          path: [DBNames.C_TUTORIALS],
          where: whereFilter,
          onComplete: (result) => {
            result.docs.forEach(
              e => {
                let tutorial = <Tutorial>e.data();
                tutorial.id = e.id;
                if(endOftutorialList.id != tutorial.id)
                  this.tutorials.push(tutorial);
              }
            );
            
          }
        }
      );
    } catch (err) {}
  }
}
