<div id="main-navbar">
  <!-- LARGE SCREENS -->
  <div id="main-navbar-width" style="position: relative;">
    <table style="position: absolute; top: 0; left: .5em; height: 4em;">
      <tr>
        <td><a routerLink=""><img style="width: 3em" alt="Codeible Logo" src="assets/images/icons/codible_256.png"></a>
        </td>
        <td> <a routerLink="videotutorials">
            <h3 routerLinkActive="main-navbar-active">Video Tutorials</h3>
          </a></td>

        <!--TODO
            <td> <button routerLink="projects">
            <h3 routerLinkActive="main-navbar-active">Projects</h3>
          </button></td>
          -->

        <!--<td> <a routerLink="quizes" matTooltip="Test Your Knowledge">
            <h3 routerLinkActive="main-navbar-active">TYK</h3>
        </a></td>-->
        <!--<td> <a routerLink="tools">
            <h3 routerLinkActive="main-navbar-active">Tools</h3>
          </a></td>-->
        <td> <a routerLink="policy">
            <h3 routerLinkActive="main-navbar-active">Policy</h3>
          </a></td>
      </tr>
    </table>
    <table style="position: absolute; top: 0; right: 1em;">
      <tr>
        <td *ngIf="isSignedIn()">
          <div style="position: relative;">
            <div style="position: absolute; top: 0; right: 3.5em; width: 12em; text-align: right; opacity: .3;"
              matTooltip="Codeible Coin">
              <img alt="Codeible Coin" *ngFor="let life of maxPlayerLifeContainer" style="width: 1.5em;"
                src="assets/images/icons/codible_256.png">
            </div>
            <div style="position: absolute; top: 0; right: 3.5em; width: 12em; text-align: right;"
              matTooltip="Codeible Coin">
              <img alt="Codeible Coin" *ngFor="let life of playerLife" style="width: 1.5em;"
                src="assets/images/icons/codible_256.png">
            </div>
            <span style="font-weight: bold; font-size: .8em; margin-left: .5em"
              matTooltip="When the time reaches 0, you will gain a Codeible Coin!">{{convertCodeibleCounterToTimer()}}</span>
          </div>
        </td>
        <td>
          <button>
            <h3>{{getUserName()}}</h3>
          </button>
        </td>
        <td *ngIf="isSignedIn()">
          <div style="position: relative;">
            <button (click)="onShowAccountPopup()">
              <span class="material-icons">settings</span>
            </button>
            <div *ngIf="shouldShowAccountPopup" class="account-popup" (click)="onShowAccountPopup()">
              <a (click)="onAccountPopupItemClick()" routerLink="bookmarks">Bookmarks</a>
              <button (click)="onSignoutClick()">Sign Out</button>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <!-- SMALL SCREENS -->
  <div style="position: relative;" id="main-navbar-small-width">
    <table style="position: absolute; top: 0; left: 0;">
      <tr>
        <td><button style="padding: .7em 2em;" (click)="onShowSideNavbarClick()"><span style="font-size: 3em;"
              class="material-icons">dehaze</span></button></td>
      </tr>
    </table>
  </div>
</div>
<!--SIDE NAV-->
<div id="main-side-navbar"
  [ngClass]="{'main-side-navbar-active': shouldShowMainSideNavbar, 'main-side-navbar-idle': !shouldShowMainSideNavbar}">
  <div class="main-side-navbar-item "><button routerLink="" (click)="onMainSideNavbarItemClick()"><img
        alt="Codeible Logo" style="width: 3em; margin-top: 1em;" src="assets/images/icons/codible_256.png"
        routerLink=""></button></div>
  <hr>
  <div class="main-side-navbar-item "><a routerLink="videotutorials" (click)="onMainSideNavbarItemClick()">
      <h3>Video Tutorials</h3>
    </a></div>

  <!--TODO
      <div class="main-side-navbar-item "><button routerLink="projects" (click)="onMainSideNavbarItemClick()">
      <h3>Projects</h3>
    </button></div>
    -->

  <!--<div class="main-side-navbar-item "><a routerLink="quizes" (click)="onMainSideNavbarItemClick()">
      <h3>Test Your Knowledge</h3>
    </a></div>-->

  <div class="main-side-navbar-item "><a routerLink="policy" (click)="onMainSideNavbarItemClick()">
      <h3>Policy</h3>
    </a></div>
  <hr>
  <!--LIFE-->
  <div *ngIf="isSignedIn()" class="main-side-navbar-item "><button>
      <div style="margin-top: 1em; position: relative;">
        <div style="position: absolute; top: 0; left: 0em; width: 12em; text-align: left; opacity: .3;">
          <img alt="Codeible Coin" *ngFor="let life of maxPlayerLifeContainer" style="width: 1.5em;"
            src="assets/images/icons/codible_256.png">
        </div>
        <div style="position: absolute; top: 0; left: 0em; width: 12em; text-align: left;">
          <img alt="Codeible Coin" *ngFor="let life of playerLife" style="width: 1.5em;"
            src="assets/images/icons/codible_256.png">
        </div>
        <span style="font-weight: bold; font-size: .8em; margin-left: 10.5em">{{convertCodeibleCounterToTimer()}}</span>
      </div>
    </button></div>
  <!--ACCOUNT STATUS-->
  <div class="main-side-navbar-item "><button>
      <h3>{{getUserName()}}</h3>
    </button></div>
  <!--ACCOUNT SETTINGS-->
  <div *ngIf="isSignedIn()" class="main-side-navbar-item "><button (click)="onShowAccountPopupSideNavClick()">
      <h3 style="display: flex; align-items: center;"><span style="margin-right: 1em;"
          class="material-icons">settings</span> Account </h3>
    </button>
    <div class="account-popup-side-nav" *ngIf="shouldShowAccountSidePopup" (click)="onShowAccountPopupSideNavClick()">
      <button (click)="onAccoutPopupSideItemClicl()" routerLink="bookmarks">Bookmarks</button>
      <button (click)="onSignoutClick()">Sign Out</button>
    </div>

  </div>
</div>

<router-outlet></router-outlet>
<!--Admin console-->
<app-console *ngIf="showConsole"></app-console>
<!--Asks user to enter a name for themselves-->
<app-initial-signup *ngIf="showInitialSignup"></app-initial-signup>
<app-sign-in></app-sign-in>