<div *ngIf="isEligibleToCreate()" id="create-course">
  <div id="create-course-page">
    <label for="createCourseImgInput"><div #createCourseImg id="create-course-img">
      <div class="k-center-box" style="height: 100%;">
        <div class="k-center-box-content">
          <h1>{{getCourseImageText(createCourseImg)}}</h1>
        </div>
      </div>
    </div></label>
    <input #imageInput (change)="onImageSelected(imageInput, createCourseImg)" class="hide" type="file" accept="image/*" id="createCourseImgInput">
    <input placeholder="Title" id="create-course-title-input" maxlength="100" />
    <textarea placeholder="Description" id="create-course-desc-input" maxlength="300"></textarea>
    <input placeholder="tag1, tag2, tag3" id="create-course-tags-input" maxlength="200" />
    <input *ngIf="isEligibleToSell()" placeholder="Cost (Pennies)" id="create-course-cost-input" maxlength="10" />
    <button id="create-course-save-button" (click)="onSaveButtonClick()">{{getSaveButtonText()}}</button>
  </div>
</div>


<!--Is not creator or privileges-->
<div *ngIf="!isEligibleToCreate()" id="create-course-no-access">
  <h1>Cannot access this page.</h1>
</div>
