<div id="console">
  <div *ngIf="show" id="console-content">
    <div id="console-content-grid">
      <div class="console-item" (click)="onCreateTutorialClick()">
        <h1>Create a tutorial</h1>
      </div>
      <div class="console-item" (click)="onCreateCourseClick()">
        <h1>Create a course</h1>
      </div>
      <div class="console-item" (click)="onCreateQuizClick()">
        <h1>Create a quiz</h1>
      </div>
    </div>
  </div>
</div>

<button (click)="onConsoleClick()" id="console-button" [ngClass]="{'slide-in': showConsoleButton, 'slide-out': !showConsoleButton}">
  {{consoleText}}
</button>