import { Component, Input, OnInit } from '@angular/core';
import { QuizQuestionResult, UserQuizHistory } from 'src/app/classes/QuizItems';

@Component({
  selector: 'app-view-quiz-results',
  templateUrl: './view-quiz-results.component.html',
  styleUrls: ['./view-quiz-results.component.css']
})
export class ViewQuizResultsComponent implements OnInit {
  @Input() show: boolean;
  @Input() closeClick: () => {};
  @Input() results: QuizQuestionResult [];

  
  constructor() { }

  ngOnInit(): void { 
  }

  onCloseClick(){
    this.closeClick(); 
  }

}
