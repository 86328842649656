import { Component, OnInit, Input } from '@angular/core';
import { VideoComponent } from '../../tools/video/video.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-display',
  templateUrl: './video-display.component.html',
  styleUrls: ['./video-display.component.css']
})
export class VideoDisplayComponent implements OnInit {
  @Input() extractedContent: string;
  @Input() extractedAdditionalParams: string [];
  isYoutube = false;
  src: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  getUrl(){
    try{
      
      if(this.src) return this.src;
      for(let additionalParams of this.extractedAdditionalParams) {
        if(additionalParams == "yt") {
          return this.src = this.sanitizeUrl(VideoComponent.getYoutubeEmbedPrefix() + this.extractedContent);
        }
        
      }
    } catch (err) {

    }
  }

  isOnlineVideo(): boolean{
    return (this.isYoutube);
  }

  sanitizeUrl(src: string){
    return this.domSanitizer.bypassSecurityTrustResourceUrl(src);
  }

}
