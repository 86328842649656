<mat-card class="quiz-content">
    <mat-card-header>
        <mat-card-title class="break-word">{{question?.question}}</mat-card-title>
        <mat-card-subtitle class="break-word">{{question?.score}} points</mat-card-subtitle>
    </mat-card-header>
    <div style="width: 100%; padding: 0 1em 1em 1em;">
        <p [textContent]="question?.details"></p>
        <input #answerInput (keyup)="onAnswerEnter(answerInput)" placeholder="Put your answer here..." maxlength="2000"> 
    </div>

    <mat-card-actions align="end">
        <button *ngIf="isNotLastQuestion()" mat-button matStepperNext (click)="onNextClick()">Next</button>
        <button *ngIf="!isNotLastQuestion()" mat-button (click)="onSubmitClick()">Submit</button>
    </mat-card-actions>
</mat-card>
