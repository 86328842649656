import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './2_x_x/pages/home/home.component';
import { ExploreComponent } from './2_x_x/pages/explore/explore.component';
import { EditTutorialComponent } from './2_x_x/pages/edit-tutorial/edit-tutorial.component';
import { ViewTutorialComponent } from './2_x_x/pages/view-tutorial/view-tutorial.component';
import { CoursesComponent } from './2_x_x/pages/courses/courses.component';
import { ViewCourseComponent } from './2_x_x/pages/view-course/view-course.component';



import { CreateCourseComponent } from './pages/create-course/create-course.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { BookmarksComponent } from './2_x_x/pages/bookmarks/bookmarks.component';
import { QuizViewComponent } from './2_x_x/pages/quiz-view/quiz-view.component';
import { CreateQuizComponent } from './2_x_x/pages/create-quiz/create-quiz.component';
import { CourseFilesDownloadComponent } from './2_x_x/pages/course-files-download/course-files-download.component';
import { QuizesComponent } from './2_x_x/pages/quizes/quizes.component';
import { ProjectsComponent } from './2_x_x/pages/projects/projects.component';
import { ToolsComponent } from './2_x_x/pages/tools/tools.component';


const routes: Routes = [
  {path: "", component: HomeComponent, data: {loggedIn: false}},
  //{path: "explore", component: ExploreComponent},
  {path: "videotutorials", component: CoursesComponent},
  {path: "courses", component: CoursesComponent},
  {path: "quizes", component: QuizesComponent},
  {path: "projects", component: ProjectsComponent},
  {path: "tools", component: ToolsComponent},
  {path: "create/tutorial", component: EditTutorialComponent},
  {path: "create/tutorial/:id", component: EditTutorialComponent},
  {path: "view/tutorial/:id", component: ViewTutorialComponent},

  {path: "create/course", component: CreateCourseComponent},
  {path: "view/course/:id", component: ViewCourseComponent},
  {path: "view/videotutorial/:id", component: ViewCourseComponent},
  {path: "view/quiz/:id", component: QuizViewComponent},
  {path: "create/quiz", component: CreateQuizComponent},
  {path: "coursefiles/:fileId", component: CourseFilesDownloadComponent},

  {path: "policy", component: PolicyComponent},
  {path: "bookmarks", component: BookmarksComponent},

  {path: "**", component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
