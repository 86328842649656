import { Component, OnInit, Input } from '@angular/core';
//import { TutorialContent } from 'src/app/classes/TutorialContent';


@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  public readonly STATE_VIDEO = "v";
  public readonly STATE_VIDEO_LINK = "link";
  public readonly STATE_YOUTUBE_LINK = "yt";
  public readonly YOUTUBE_EMBED_URL = "https://www.youtube.com/embed/";
  public readonly YOUTUBE_VIDEO_URL = "https://www.youtube.com/watch?v=";
  //@Input() content: TutorialContent [];

  constructor() { }

  ngOnInit() {
  }
  addVideoLinkButtonClick() {
    const link = (<HTMLInputElement>document.getElementById("video-link-input")).value;
    try{
      if(link.length < 5) return;

      if(this.isYoutubeLink(link)) {
        const videoIdString = link.split("watch?v=")[1];
        const filteredVideoIdString = videoIdString.substring(0, videoIdString.indexOf("&") > -1? videoIdString.indexOf("&"): videoIdString.length);
        if(filteredVideoIdString.length > 5) {
          /*const tutorialContent = new TutorialContent(
            {
              type: this.getYoutubeVideoLinkPath(),
              content: filteredVideoIdString
            }
            );
          this.content.push(tutorialContent);*/
        }
       
      }
    } catch (err) {

    }
  }

  isYoutubeLink(link: string){
    return link.match("youtube.com");
  }

  getYoutubeVideoLinkPath(){
    return `${this.STATE_VIDEO}, ${this.STATE_VIDEO_LINK}, ${this.STATE_YOUTUBE_LINK}`;
  }

  public static getYoutubeEmbedPrefix(): string {
    return "https://www.youtube.com/embed/";
  }

  onVideoInputChange(videoInput: HTMLInputElement){
    
    const link = videoInput.value;
    
    try{
      if(link.length < 5) return;

      if(this.isYoutubeLink(link)) {
        const videoIdString = link.split("watch?v=")[1];
        const filteredVideoIdString = videoIdString.substring(0, videoIdString.indexOf("&") > -1? videoIdString.indexOf("&"): videoIdString.length);
        if(filteredVideoIdString.length > 5) {
          const iFrame = <HTMLIFrameElement>document.getElementById("video-link");
          
          iFrame.src = VideoComponent.getYoutubeEmbedPrefix() + filteredVideoIdString;
          if(iFrame.src) {
            iFrame.style.width = "560px";
            iFrame.style.height = "315px";
          }
          
        }
      }
    } catch (err) {

    }
  }

}
