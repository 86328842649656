import { Component, Input, OnInit } from '@angular/core';
import { Question } from 'src/app/classes/QuizItems';


@Component({
  selector: 'app-add-multiple-choice',
  templateUrl: './add-multiple-choice.component.html',
  styleUrls: ['./add-multiple-choice.component.css']
})
export class AddMultipleChoiceComponent implements OnInit {

  @Input() question: Question;

  options: string [] = []; 
  optionsArray: string [] = [];

  constructor() { }

  ngOnInit(): void {
    this.question.mcOptions = this.optionsArray;
  }


  onAddOptionClick(){
    this.options.push("");
  }

  onOptionKeyUp(i: number, optionInput: HTMLInputElement){
    let option = optionInput.value;
    this.optionsArray[i] = option;
  }

  onQuestionEnter(questionInput: HTMLInputElement){
    this.question.question = questionInput.value;
  }
  onCorrectChoiceEnter(correctChoiceInput: HTMLInputElement){
    this.question.correctAnswer = correctChoiceInput.value;
  }

  onScoreEnter(scoreInput: HTMLInputElement) {
    this.question.score = Number.parseInt(scoreInput.value);
  }
}


