
<div *ngIf="ableToView" id="quiz-page">
    <mat-vertical-stepper linear>
        <mat-step *ngFor="let question of questions; let i = index;" [editable]="false" [label]="'Question ' + (i + 1)"  [completed]="question?.completed ? question.completed: false">
            <div [ngSwitch]="question?.type">
                <app-multiple-choice *ngSwitchCase="'MC'" 
                [question]="question" [questionNumber]="i" [numberOfQuestions]="questions?.length"
                [sumitClick]="onSubmitClick"
                ></app-multiple-choice>
                <app-short-answer *ngSwitchCase="'SA'" 
                [question]="question"  [questionNumber]="i" [numberOfQuestions]="questions?.length"
                [sumitClick]="onSubmitClick"
                ></app-short-answer>
            </div>
        </mat-step>
    </mat-vertical-stepper>

</div>
<div *ngIf="!ableToView" id="quiz-page-blocker">
    <div id="quiz-page-blocker-content">
        <div *ngIf="!signedIn">
            <h1>Log in to view this page</h1>
        </div>
        <div *ngIf="signedIn && quizHistory?.completed">
            <h3>You already completed this test</h3>
            <h2>{{quiz?.topic}} - {{quiz?.subtopic}}</h2>
            <h2>{{quiz?.title}}</h2>
            <button (click)="onRetakeClick()" mat-flat-button color="primary">Retake test?  <img src="assets\images\icons\codible_256.png" width="32px"> X {{quiz?.cost}}</button>
            <button mat-flat-button color="accent" (click)="onSeeResultsClick()">See results</button>
        </div>
    </div>
</div>

<app-view-quiz-results *ngIf="signedIn" [show]="shouldShowResults" [closeClick]="hideResults" [results]="quizHistory?.questionResults" ></app-view-quiz-results>
<app-quiz-high-score-view [state]="highScoreState()" [quizId]="quizId"></app-quiz-high-score-view>
<app-footer></app-footer>