

<div class="policy-page"> 
  <app-intro></app-intro>
  <app-cookies></app-cookies>
  <app-security></app-security>
  <app-account></app-account>
  <app-contact-us></app-contact-us>
</div>


<div class="scroll-down-section ">
  <p>- Scroll down -</p>
</div>