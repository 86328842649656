<div [ngClass]="{'quiz-results-page': true, 'idle': !show, 'active': show}">
    <div class="quiz-results-actions">
        <button mat-button class="" (click)="onCloseClick()"><mat-icon>close</mat-icon></button>
        
    </div>
    <div class="quiz-results-page-content">
        <div *ngFor="let result of results; let i = index;" class="quiz-result">
            <mat-icon [ngClass]="{'correct': result.correct, 'incorrect': !result.correct}">{{result?.correct? "check": "close"}}</mat-icon><p>{{i + 1}}</p><p>{{result?.question}}</p>
        </div>
    </div>
</div>  