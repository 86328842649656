import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  script: HTMLScriptElement;

  constructor() {

  }

  set(src: string) {
    this.script = document.createElement("script");
    this.script.src = src;
  }

  run() {
    document.body.appendChild(this.script);
  }

  destroy() {
    try {
      document.body.removeChild(this.script);
    } catch (err) {

    }
  }
}
