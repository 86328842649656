import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { FirebaseTSStorage } from 'firebasets/firebasetsStorage/firebaseTSStorage';
import { FirebaseTSFirestore } from 'firebasets/firebasetsFirestore/firebaseTSFirestore';
import { DBNames } from 'src/app/classes/DBNames';
import { Router } from '@angular/router';
//import { ICourse } from 'src/app/tools/create-group/create-group.component';

@Component({
  selector: 'app-create-course',
  templateUrl: './create-course.component.html',
  styleUrls: ['./create-course.component.css']
})
export class CreateCourseComponent implements OnInit {
  private firestore: FirebaseTSFirestore;
  private storage: FirebaseTSStorage;
  constructor(private router: Router) {
    this.firestore = new FirebaseTSFirestore();
    this.storage = new FirebaseTSStorage();
  }

  ngOnInit() {
  }

  isEligibleToCreate(){
    return AppComponent.isSignedIn && (AppComponent.firebaseUser.isAdmin());
  }

  isEligibleToSell(){
    return AppComponent.firebaseUser.isAdmin();
  }

  onImageSelected(imageInput: HTMLInputElement, createCourseImg: HTMLDivElement) {
    const fileReader = new FileReader();
    const file = imageInput.files[0];
    fileReader.readAsDataURL(file);
    fileReader.onloadend = (ev) => {
      
      try{
        const results:string = <string>fileReader.result;
        createCourseImg.style.backgroundImage = `url(${results})`;
      } catch (err) {}
    }
  }

  getCourseImageText(createCourseImg: HTMLDivElement){
    return createCourseImg.style.backgroundImage? "" : "Choose Image";
  }

  getSaveButtonText(){
    return "Create Course";
  }

  onSaveButtonClick() {
    const imageFile = (<HTMLInputElement>document.getElementById("createCourseImgInput")).files[0];
    const title = (<HTMLInputElement>document.getElementById("create-course-title-input")).value;
    const desc = (<HTMLTextAreaElement>document.getElementById("create-course-desc-input")).value;
    const tags = (<HTMLInputElement>document.getElementById("create-course-tags-input")).value.replace(" ", "");
    const cost = AppComponent.isAdmin()? (<HTMLInputElement>document.getElementById("create-course-cost-input")).value : "0";
    if(imageFile && title && desc && cost) {
      this.firestore.create(
        {
          path: [DBNames.C_COURSES],
          data: <any>{
            title,
            desc,
            tags,
            cost,
            creatorId: AppComponent.firebaseUser.id,
            topic: "",
            published: false
          },
          onComplete: (docId) => { 
            this.storage.upload(
              {
                uploadName: imageFile.name + imageFile.size + imageFile.type,
                path: [DBNames.C_COURSES, docId, "image"],
                data: {
                  data: imageFile
                },
                onComplete: (downloadUrl) => {
                  this.firestore.update(
                    {
                      path: [DBNames.C_COURSES, docId],
                      data: {
                        imgUrl: downloadUrl
                      },
                      onComplete: (docRef) => {
                        this.router.navigate(['myLibrary']);
                      }
                    }
                  );
                }
              }
            );
          }
        }
      );

    }
  }
}
