<div style="height: 100%;" class="k-center-box">
  <div  class="k-center-box-content" id="contacts-policy-content">
    <div>

    </div>
    <div id="contacts-desc">
      <h1>Policy Updates</h1>
      <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page</p>
      <!--<h1>Contact Us</h1>
      <p>kingwaimark@gmail.com</p>-->
    </div>
  </div>
</div>