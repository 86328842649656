import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() message?: string;
  @Input() subMessage?: string;
  @Input() details?: string;
  @Input() confirmClick?: any;
  @Input() cancelClick?: any;

  constructor() { }

  ngOnInit() {
  }

  onConfirmClick(){
    try{
      this.confirmClick();
    } catch (err) {}
  }

  onCancelClick(){
    try{
      this.cancelClick();
    } catch (err) {}
  }

}
