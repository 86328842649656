import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ScriptService } from 'src/app/services/script.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentYear: string;

  constructor(private router: Router, private youtubeButtonScript: ScriptService, private twitterButtonScript: ScriptService, @Inject(PLATFORM_ID) private platformId: Object) { 
  }
  ngOnInit() {
    this.setCurrentYear();
    if(isPlatformBrowser(this.platformId)) {
      this.youtubeButtonScript.set("https://apis.google.com/js/platform.js");
      this.twitterButtonScript.set("https://platform.twitter.com/widgets.js"); 
      this.youtubeButtonScript.run();
      this.twitterButtonScript.run();
    }
  }
  ngOnDestroy(){
    this.youtubeButtonScript.destroy();
    this.twitterButtonScript.destroy();
  }
  
  setCurrentYear(){
    this.currentYear = new Date().getFullYear().toString();
  }

  onPolicyClick() {
    this.router.navigate(['policy']);
  }
}
