<mat-card class="quiz-content">
    <mat-card-header>
        <mat-card-title class="break-word">{{question?.question}}</mat-card-title>
        <mat-card-subtitle class="break-word">{{question?.score}} points</mat-card-subtitle>
    </mat-card-header>
    <div>
        <label class="container" *ngFor="let option of question?.mcOptions; let i = index;">{{option}}
            <input type="radio" name="{{'Question ' + (questionNunmber + 1)}}" value="{{option}}"
                (change)="onRadioButtonChecked(i)">
            <span class="checkmark"></span>
        </label>

    </div>

    <mat-card-actions align="end">
        <button *ngIf="isNotLastQuestion()" mat-button matStepperNext (click)="onNextClick()">Next</button>
        <button *ngIf="!isNotLastQuestion()" (click)="onSubmitClick()" mat-button>Submit</button>
    </mat-card-actions>
</mat-card>