import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, ElementRef, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-tutorial-content',
  templateUrl: './tutorial-content.component.html',
  styleUrls: ['./tutorial-content.component.css']
})
export class TutorialContentComponent implements OnInit {
  @Input() content: string | any;
  contentParser: ContentParser;
  constructor(private domSanitizer: DomSanitizer, private _snackBar: MatSnackBar, @Inject(PLATFORM_ID) private platformId) { }
  @ViewChild('gistFrame') iframe: ElementRef;

  ngOnInit() { 
    this.contentParser = new ContentParser(this.content.stringValue? this.content.stringValue: this.content, this.domSanitizer);

  }

  ngAfterViewInit() {
    if(isPlatformBrowser(this.platformId)) {
      if(this.contentParser.isGist()) {
        this.genGistContent();
      }
    }

  }


  genGistContent(){
    try {
      
      let doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
      const content = `
        <html>
          <head>
            <base target="_parent">
          </head>
          <body>
            <script type="text/javascript" src="${this.contentParser?.parseGistURL()}"></script>
          </body>
        </html>
      `;
      doc.open();
      doc.write(content);
      doc.close();
    } catch (err) {

    }
  }
  isFocused(){
    return this.contentParser?.isFocused;
  }
  addTextToClipboard(contentParagraph: HTMLParagraphElement){
    let content = contentParagraph.textContent;
    let regex = new RegExp("\\s\\s+", "g");
    console.log(regex.exec(content))
    content = content.replace(/\s\s+/g, "\n");
    navigator.clipboard.writeText(content).then(
      (v) => {
        this._snackBar.open(
          "Copied to clipboard!", 
          "Close", 
          {
            duration: 2000,
          });
      }
    );
    
  }
}



export class ContentParser {
  /*
    In the content string, it contains metadata and the content itself.
    Metadata can be found at the beginning of the content string in a form like this:

    [txtbox, ..., ..., ...]Contents

    The first element in the metadata is the content type
    The other part are the styles and attributes used to style and modify the content
    After the metadata is the contents of the content item
  */
  content: string;
  metadata: string [];
  videoUrl: SafeResourceUrl;
  /*
    The type of the content.
    1 = Textbox
    2 = Image
    3 = Embed Video
    4 = Gist
  */
  type: number = 0;
  styles: {} = {};
  isJumpLocation = false;
  isFocused = false;
  domSanitizer: DomSanitizer;
  constructor(content: string, domSanitizer: DomSanitizer){
    this.domSanitizer = domSanitizer;
    let parsedContent = this.parse(content);
    this.content = parsedContent.content;
    this.metadata = parsedContent.metadata;
    this.type = parsedContent.type;
    //this._test();
  }
  
  parse(content_str: string){
    let beginDelimeter = content_str.indexOf("[")+1;
    let endDelimeter = content_str.indexOf("]");
    let metadata = content_str.substring(beginDelimeter, endDelimeter).split(",");
    let content = content_str.substring(endDelimeter + 1, content_str.length);
    let type = this.extractType(metadata);
    this.styles = this.extractStyles(metadata);
    return {metadata, content, type};
  }

  _test(){
    this._printMetadata();
    this._printVideoUrl();
  }
  _printMetadata(){
    console.log("PRINTING METADATA");
    console.log(`${this.metadata}`);
  }
  _printVideoUrl(){
    if(this.type != 3) return;
    console.log("PRINTING VIDEO URL");
    console.log(this.videoUrl);
  }

  extractType(metadata: string []){
    let type = metadata[0];
    switch(type){
      case "txtbox":
        return 1;
      case "img":
        return 2;
      case "v":
        return 3;
      case "gist":
        return 4;
    }
    return 0;
  }

  extractStyles(metadata: string[]){
    let styles = <Styles>{};
    for(let atr of metadata) { 
      try{
        if(atr.match("style")) {
          switch(atr.split(":")[1]) {
            case "focus":
              styles["background-color"] = "var(--primary-color-dark-1)";
              styles["padding"] = "10px 20px";
              //styles["box-shadow"] = "0px 0px 2px 2px rgba(0, 0, 0, 0.200)";
              styles["border-radius"] = "5px";
              this.isFocused = true;
              break;
            case "Left": 
              styles["text-align"] = "left";
              break;
            case "Center": 
              styles["text-align"] = "center";
              break;
            case "Right": 
              styles["text-align"] = "right";
              break;    
            case "title":
              styles["font-size"] = "var(--content-title-size)";
              styles["margin-top"] = "var(--content-title-margin-top)";
              styles["font-weight"] = "var(--content-bold-font)";
              this.isJumpLocation = true;
              break;
            case "subtitle":
              styles["font-size"] = "var(--content-subtitle-size)";
              styles["margin-top"] = "var(--content-subtitle-margin-top)";
              styles["font-weight"] = "var(--content-bold-font)";
              this.isJumpLocation = true;
            case "original":
              styles["width"] = "auto"; 
              break;
            case "fullWidth":
              styles["width"] = "100%";
              break;
          }
        }
      } catch (err) {

      }
    }

    return styles;
  }

  getStyles(){
    return this.styles;
  }
  getVideoUrl(): SafeResourceUrl{
    if(this.videoUrl) return this.videoUrl;
    return this.videoUrl = this.sanitizeUrl(this.genYoutubeEmbedPrefix() + this.content + "");
  }
  genYoutubeEmbedPrefix(): string {
    return "https://www.youtube.com/embed/";
  }
  sanitizeUrl(src: string){
    return this.domSanitizer.bypassSecurityTrustResourceUrl(src);
  }

  isGist(){
    return this.type == 4;
  }

  parseGistURL(){
    if(this.isGist()) {
      return this.content.split("\"")[1];
    }
    return "";
  }


}

export interface Styles {
  "background-color"?: string;
  "padding"?: string;
  "box-shadow"?: string;
  "border-radius"?: string;
  "text-align"?: string;
  "border-width": string;
  "border-style": string;
  "border-color": string;
  "width": string;
  "title": string;
  "subtitle": string;
}

export enum TutorialContentType {
  TEXT_BOX,
  IMG,
  VIDEO
}