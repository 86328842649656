<div id="view-course">
  <div class="k-md-slider">
    <div class="master" master>
      <!--TUTORIAL LIST-->
      <div content id="view-course-tutorial-list">



        <a *ngFor="let tutorial of tutorials" [href]="getRouterLink(tutorial)">
          <!--
            [routerLink]="getRouterLink(tutorial)"
          (click)="onTutorialClick(tutorial)"
          -->
          <div [ngClass]="{'tutorial-list-item': true, 'selected-tutorial': isSelectedTutorial(tutorial)}">
            <span>{{tutorial?.mapValue.fields.title.stringValue}}</span>

            <div style="margin: 10px 0 0 0;" *ngIf="isAdmin()">
              <button (click)="onTutorialDelete(tutorial)"><i class="material-icons">delete</i></button>
              <button *ngIf="!swappingTutorial" (click)="onSwapClick(tutorial)"><i
                  class="material-icons">cached</i></button>
            </div>
          </div>
        </a>
      </div>
    </div>
    <!--TUTORIAL DISPLAY-->
    <div id="tutorial-display" class="details" details style="position: relative;">
      <div *ngIf="currentSelectedTutorial && isEligibleToView()" id="tutorial-display-content">
        <div id="tutorial-display-ad-slots">
          <div>
            <!-- CODEIBLE_VIDEO_TUTORIAL_BOX_AD_1 -->
            <app-google-ad [adStyle]="'display:inline-block;width:300px;height:300px'" [adSlot]="'6998592733'"></app-google-ad>
          </div>
          <div>
            <!-- CODEIBLE_VIDEO_TUTORIAL_BOX_AD_2 -->
            <app-google-ad [adStyle]="'display:inline-block;width:300px;height:300px'" [adSlot]="'3468549444'"></app-google-ad>
          </div>
        </div>
        <div id="view-course-tutorial">
          <div style="text-align: center; overflow: hidden;">
              <!-- CODEIBLE_VIDEO_TUTORIAL_TOP_AD -->
              <app-google-ad [adStyle]="'display:inline-block;min-width:320px;max-width:970px;width:100%;height:90px'" [adSlot]="'1269406298'"></app-google-ad>
          </div>
          <app-tutorial-page [tutorial]="currentSelectedTutorial" [contentArray]="selectedTutorialContent?.values">
          </app-tutorial-page>
          <br>
          <button id="next-button" (click)="onNextButtonClick()">Next</button>
        </div>
        </div>
      <app-footer></app-footer>
      <!-- Not able to view blocker
      <div [ngClass]="{'show': !isEligibleToView(), 'hide': isEligibleToView()}" id="not-eligible-view">
        <div id="not-eligible-view-content" [ngClass]="{'show': isSignedIn, 'hide': !isSignedIn}">
          <h1 class="price">{{course?.cost > 0? "$": ""}} <span class="price-tag">{{getCost(course?.cost) == "Free"?
              "Free": getCost(course?.cost) | number: "1.2-2"}}</span></h1>
          <p>You need to purchase this course to view its contents.</p>
          <hr>
          <div id="course-payment"></div>
        </div>
        <div id="not-eligible-view-content" *ngIf="!isSignedIn">
          <h1>Must sign in to purchase.</h1>
          <p>You need to purchase this course to view its contents.</p>
        </div>
      </div>-->

    </div>
  </div>
</div>

<app-confirm-dialog message="You are about to delete {{selectedTutorialToDelete?.title}}" subMessage="Are you sure?"
  *ngIf="showDeleteMessage" [confirmClick]="onConfirmDelete" [cancelClick]="onCancelDelete">
</app-confirm-dialog>