import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit {
  title = "Security";
  desc = "We value your trust in providing us your personal information, thus we are striving to use commercially acceptable means of protecting it. We cannot guarantee its absolute security. Remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable. ";
  linksToOtherSites = "Links to Other Sites";
  linksToOtherSitesDesc = "Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.";
  logData = "Log Data";
  logDataDesc = "We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer's Internet Protocol (“IP”) address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.";
  constructor() { }

  ngOnInit() {
  }

}
