import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
  title = "Private Policy";
  description = `Codeible operates Codeible.com, which provides opportunities for viewers to learn and deploy professional applications.
                This page is used to inform website visitors regarding our policies: the use, collection, and disclosure of personal information if anyone decided to use our Service.
                If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The personal information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
                `;
  infoCollection = "Information Collection and Use";
  infoCollectionDesc = "For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.";
  childrePrivacy = "Children's Privacy";
  childrenPrivacyDesc = "Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.";
  constructor() { }

  ngOnInit() {
  }

}
