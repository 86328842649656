
<div class="course">
  <div *ngFor="let course of courses | async" class="course-item">

    <a [routerLink]="genCourseRouter(course)"><app-course-header [course]="course" [courses]="courses" ></app-course-header></a>
    <!--CREATOR TOOLS-->
    <!--<app-course-header-creator-tools [course]="course" [courses]="courses"></app-course-header-creator-tools>-->
  </div>
  
</div>

<app-footer></app-footer>