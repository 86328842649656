import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Question } from 'src/app/classes/QuizItems';

//import { MultipleChoice } from 'src/app/classes/QuizItems';

@Component({
  selector: 'app-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.css']
})
export class MultipleChoiceComponent implements OnInit {

  @Input() question: Question;
  @Input() questionNumber: number;
  @Input() numberOfQuestions: number;
  @Input() sumitClick?: () => any;

  constructor() {

  }

  ngOnInit(): void {
    this.question.completed = false;
  }

  onRadioButtonChecked(selectedRadioButtonIndex: number){
    this.question.userAsnwer = `${selectedRadioButtonIndex + 1}`;
    this.question.completed = true;
  }

  isNotLastQuestion(){
    return this.questionNumber < this.numberOfQuestions - 1;
  }

  onNextClick(){
 
  }

  onSubmitClick(){
    this.sumitClick();
  }
}
