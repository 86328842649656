<div>
    <div style="text-align: center;">
        <h2>Congratulations!</h2>
        <p>Your score is</p>
        <h1 class="score">{{getScore()}}<span class="max-score">/{{getMaxScore()}}</span></h1>
    </div>
    <mat-dialog-actions align="end">
        <button mat-button [disabled]="!finishedUpdaingScore" (click)="onCloseClick()">Close</button>
    </mat-dialog-actions>
</div>
